import React from 'react'
import { Card, CardBody, CardTitle } from '../../../core'
import styles from './action-result-item.module.css'

export const ActionResultItemCard = ({ hasData = true, header, headerIcon, body, ...rest }) => {
  return (
    <Card classes={`${styles.details}`}>
      <CardTitle classes={`${styles.header} ${!hasData ? styles.light : ''}`}>
        {header && <span>{header}</span>}
        {headerIcon && <span>{headerIcon}</span>}
      </CardTitle>
      {body && <CardBody classes={styles.body}>{body}</CardBody>}
    </Card>
  )
}
