.map {
  display: flex;
  border-radius: 6px;
  min-width: 30vw;
  min-height: 50vh;
  min-height: 400px;
}

.leaflet-container {
  height: 600px;
}
