import React from 'react'
import { accountReducer, errorReducer, forgotPassword, registerAccount } from '../../state'
import { combineReducers, fromReducer, withReducerState } from '../../utils'

const account = fromReducer(combineReducers({ account: accountReducer }, { error: errorReducer }))

export const withAccount = withReducerState(
  (state) => {
    return { ...state.account, ...state.errors }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      register: (data) => dispatch(registerAccount(data)),
      forgotPassword: (data) => dispatch(forgotPassword(data))
    }
  }
)(account)

export const withAccountErrors = withReducerState(
  (state) => {
    return { ...state.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(account)
