import React from 'react'
import styles from './form.module.css'

const TextField = React.forwardRef(
  ({ name, placeholder, label, startAdornment, endAdornment, classes, asArea, ...rest }, ref) => {
    return (
      <div className={` ${classes ? classes : ''} ${styles.input}`}>
        {startAdornment && (
          <div className={`${styles.adornment} ${styles.start}`}>{startAdornment}</div>
        )}
        {(asArea && (
          <textarea
            // cols='30'
            // rows='5'
            ref={ref}
            autoComplete='off'
            name={name}
            placeholder={placeholder}
            {...rest}
          />
        )) || (
          <input ref={ref} autoComplete='off' name={name} placeholder={placeholder} {...rest} />
        )}
        {endAdornment && <div className={`${styles.adornment} ${styles.end}`}>{endAdornment}</div>}
      </div>
    )
  }
)

TextField.Area = React.forwardRef(({ classes, placeholder, ...rest }, ref) => (
  <TextField
    asArea
    placeholder={placeholder}
    classes={`${styles.input} ${styles.area} ${classes}`}
    {...rest}
    ref={ref}
  />
))

TextField.displayName = 'TextField'
export { TextField }
