import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

export const isUndefined = (value) => !!(value === null || value === undefined)

export const getPathPrefix = (path) => {
  const prefix = path.split('/').filter((p) => p !== '' && !isUndefined(p))
  return prefix[0]
}

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
export const validateEmail = (email) => emailPattern.test(email)

const parseDateTime = (datetime, offset = 0) => moment(datetime).utcOffset(offset)

export const parseDate = (datetime, offset = 0) => {
  const momentDate = parseDateTime(datetime, offset)
  return (format) => () => momentDate.format(format)
}

export const dateFormatter = (datetime) => {
  const momentDate = parseDateTime(datetime)
  return {
    time: () => momentDate.format('HH:mm'),
    weekday: () => momentDate.format('dddd'),
    month: () => momentDate.format('MMM'),
    monthFull: () => momentDate.format('MMMM'),
    dayOfMonth: () => momentDate.format('D'),
    date: () => momentDate.format('D MMM'),
    year: () => momentDate.format('YYYY'),
    expired: () => isPastDate(momentDate),
    custom: (format) => momentDate.format(format)
  }
}

export const DATE_FORMAT_WEEKDAY = 'dddd'
export const DATE_FORMAT_YEAR = 'YYYY'
export const DATE_FORMAT_MONTH_SHORT = 'MMM'
export const DATE_FORMAT_MONTH_LONG = 'MMMM'
export const DATE_FORMAT_DATE = 'D MMM'
export const DATE_FORMAT_HOURS_MINUTES = 'HH:mm'

export const getWeekDay = (datetime) => parseDate(datetime)('dddd')

/*

  parsedate(getweekday)(daettime)

*/

export const getDayOfWeek = (datetime) => parseDateTime(datetime).format('dddd')
export const getMonth = (datetime) => parseDateTime(datetime).format('MMMM')
export const getYear = (datetime) => parseDateTime(datetime).format('YYYY')
export const getDate = (datetime) => parseDateTime(datetime).format('D MMM')
export const getTime = (datetime) => parseDateTime(datetime).format('HH:mm')
export const isPastDate = (datetime) => {
  const today = parseDateTime(new Date())
  const possiblePastDate = parseDateTime(datetime)
  return today.diff(possiblePastDate) < 0 ? false : true
}
export const isCurrentMonth = (monthYear) => {
  const givenDate = moment(monthYear, 'MMM YYYY')
  const nowDate = moment(new Date(), 'MMM YYYY')
  return givenDate.isSame(nowDate, 'month')
}

export const isPastMonth = (monthYear) => {
  const givenDate = moment(monthYear, 'MMM YYYY')
  const nowDate = moment(new Date(), 'MMM YYYY')
  const diff = nowDate.diff(givenDate, 'days')
  return diff > 0 ? true : false
}

export const sortByDate =
  (dateProp, format = 'YYYY-MM-DD') =>
  (list) =>
    list.sort((a, b) => {
      return moment(a[dateProp], format).isBefore(moment(b[dateProp], format)) ? 1 : -1
    })

export const sortActionsByDate = (actions) =>
  actions.sort((a, b) => {
    return moment(a.datetime, 'YYYY-MM-DD').isBefore(moment(b.datetime, 'YYYY-MM-DD')) ? -1 : 1
  })

export const groupActionsByDate = (actions) => {
  // Don't let the FE sort items by itself :/
  // const sortActions = sortActionsByDate(actions)
  const actionGroups = actions.reduce((grp, action) => {
    const grpKey = `${getMonth(action.datetime)} ${getYear(action.datetime)}`
    if (!grp[grpKey]) {
      grp[grpKey] = []
    }
    grp[grpKey].push(action)

    return grp
  }, {})

  return actionGroups
}

export const formatNumber = (number) => new Intl.NumberFormat('de-DE').format(number)
