import { mapConstants } from '../constants'

export const initialMapState = {
  loading: false,
  mapData: null
}
const reducerSwitch = {
  [mapConstants.MAP_SUCCESS]: (state, { payload }) => ({
    ...state,
    mapData: { ...payload },
    loading: false
  }),
  [mapConstants.MAP_REQUEST]: (state) => ({ ...state, loading: true }),
  [mapConstants.MAP_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload
  })
}

export const mapReducer = (state = initialMapState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return state
  }
}
