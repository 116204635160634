import {
  canvasReducer,
  errorReducer,
  listAllActions,
  listSeperatedActions,
  getNextSeperatedActions,
  saveAction,
  updateAction,
  getActionById,
  getQuestionnaires,
  findAddress,
  deleteAction
} from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const actions = fromReducer(combineReducers({ actions: canvasReducer }, { error: errorReducer }))

export const withCanvas = withReducerState(
  (state) => {
    return { ...state?.actions }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      listActions: () => dispatch(listAllActions()),
      listSeperatedActions: (params) => dispatch(listSeperatedActions(params)),
      getNextSeperatedActions: (resourceType) => dispatch(getNextSeperatedActions(resourceType)),
      getAction: (id) => dispatch(getActionById({ id })),
      saveAction: (actionData, cb) => dispatch(saveAction(actionData, cb)),
      updateAction: (id, actionData, cb) => dispatch(updateAction(id, actionData, cb)),
      deleteAction: (id) => dispatch(deleteAction(id)),
      getQuestionnaires: () => dispatch(getQuestionnaires()),
      findAddress: (searchString) => dispatch(findAddress(searchString))
    }
  }
)(actions)
