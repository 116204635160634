import React, { useMemo } from 'react'
import { dateFormatter } from '../../../utils'
import ActionLabel from '../../../assets/images/action-label.svg'
import styles from './action.datetime.module.css'

const ActionDateTime = ({ classes, action }) => {
  const formatter = useMemo(() => dateFormatter(action?.datetime), [action])
  return (
    <>
      <div
        className={`${classes ? classes : ''} ${
          formatter.expired() ? `hgrow-1 ${styles.expired}` : ''
        } flex gapXxs hcenter`}
      >
        <span className={styles.dayOfWeek}>{formatter.weekday()}</span>
        <span className={styles.date}>{formatter.date()}</span>
        <span className={styles.time}>{formatter.time()}</span>
        {formatter.expired() && (
          <div className={styles.icon}>
            <ActionLabel />
          </div>
        )}
      </div>
    </>
  )
}

ActionDateTime.Large = ({ ...rest }) => {
  return <ActionDateTime classes={styles.large} {...rest} />
}

export { ActionDateTime }
