import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const municipalityOverviewResource = createResource({
  endpoint: `${API_URL}/hub/dashboard/municipalities`,
  auth: () => userStorage[USER_TOKEN].get()
})
/**
 *
 * Service for the voting goal api.
 */
export const municipalityOverviewService = {
  getMunicipalityOverview: () => {
    return municipalityOverviewResource.get().json()
  }
}
