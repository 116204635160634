import { votingGoalReducer, errorReducer, getVotingGoal, saveVotingGoal } from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const voting = fromReducer(
  combineReducers({ votingGoal: votingGoalReducer }, { error: errorReducer })
)

export const withVotingGoal = withReducerState(
  (state) => {
    return { ...state?.votingGoal }
  },
  (dispatch) => {
    return {
      getVotingGoal: () => dispatch(getVotingGoal()),
      saveVotingGoal: (data) => dispatch(saveVotingGoal(data)),
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(voting)

export const withVotingGoalErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(voting)
