import {
  teamReducer,
  errorReducer,
  listUsers,
  saveUser,
  updateUser,
  inviteUser,
  getUserById,
  deleteUser
} from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const users = fromReducer(combineReducers({ team: teamReducer }, { error: errorReducer }))

export const withTeam = withReducerState(
  (state) => {
    return { ...state?.team }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      listUsers: () => dispatch(listUsers()),
      getUser: (id) => dispatch(getUserById({ id })),
      saveUser: (userData, cb) => dispatch(saveUser(userData, cb)),
      updateUser: (id, userData, cb) => dispatch(updateUser(id, userData, cb)),
      inviteUser: (id) => dispatch(inviteUser(id)),
      deleteUser: (id) => dispatch(deleteUser(id))
    }
  }
)(users)

export const withTeamErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(users)
