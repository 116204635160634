import React, { useState } from 'react'
import styles from './toggle-horizontal.module.css'

const ToggleHorizontal = ({ classes, onUpdate, value = '', options = [] }) => {
  const [selected, setSelected] = useState(value)

  const update = (value) => {
    const emitValue = value === selected ? '' : value
    setSelected(emitValue)
    onUpdate?.(emitValue)
  }
  return (
    <div className={`${styles.ToggleHorizontal} ${classes || ''}`}>
      {options.map(({ name, value, icon: Icon }) => (
        <button
          type='button'
          onClick={() => update(value)}
          className={(selected === value && styles.selected) || undefined}
          key={value}
        >
          <Icon className={styles.icon} />
          {name}
        </button>
      ))}
    </div>
  )
}
ToggleHorizontal.displayName = 'ToggleHorizontal'
export { ToggleHorizontal }
