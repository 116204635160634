.details {
  padding: 0;
  min-height: 12rem;
  flex-grow: 0;
  flex-basis: 28%;
  border: 2px solid var(--colors-lightgray);
  box-shadow: 2px 2px 0px 1px transparent;
}

.details .title,
.details .body,
.details .header {
  margin: 0;
  padding: 12px;
}

.details .title {
  display: flex;
  text-transform: capitalize;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  max-width: none;
  height: 1.8rem;
  max-height: 1.8rem;
}

.details.expired .header {
  color: var(--colors-black);
}

.details .header {
  cursor: pointer;
  padding: 18px 12px 6px 12px;
  font-size: var(--size-semilarge);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.details .body {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  margin-bottom: 12px;
}
.details .body * {
  flex-grow: 0;
}

.details .hr {
  margin: 0;
  border-bottom: 2px solid var(--colors-lightgray);
  transition: border-bottom 0.6s ease;
}
