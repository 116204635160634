export const accountConstants = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  RESET_REQUEST: 'RESET_REQUEST',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',

  FAILURE: 'FAILURE'
}
