import React from 'react'
import { CardTitle } from './card.title'
import { CardBody } from './card.body'
import styles from './card.module.css'

export const Card = React.forwardRef(
  ({ flat, classes, children, clickable = false, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={` ${flat ? styles.flat : ''} ${styles.card} ${classes ? classes : ''} ${
          clickable ? styles.interactive : ''
        }`}
        {...rest}
      >
        {children}
      </div>
    )
  }
)

Card.StatsItem = ({ label, value, classes }) => (
  <Card
    classes={`${styles.card} ${styles.stats} ${styles.border} ${styles.stretch} ${styles.fixed} ${
      classes ? classes : ''
    }`}
  >
    <CardTitle>{value}</CardTitle>
    <CardBody>{label}</CardBody>
  </Card>
)
