import moment from 'moment'

const decodeJwtToken = (token) => {
  try {
    const base64Payload = token.split('.')[1]
    const payload = Buffer.from(base64Payload, 'base64')
    return JSON.parse(payload.toString())
  } catch (err) {
    return false
  }
}

export const isValidToken = (token) => decodeJwtToken(token)

export const tokenIsExpired = (token) => {
  const tokenDecoded = decodeJwtToken(token)
  if (!tokenDecoded) {
    return true
  }
  const tokenExpiration = tokenDecoded?.exp * 1000
  const tokenExpirationTimeInSeconds = tokenExpiration - moment(Math.floor(Date.now()))
  if (tokenExpiration && tokenExpirationTimeInSeconds < 20) {
    return true
  }
  return false
}
