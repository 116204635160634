if (!('indexedDB' in window)) {
	return
}

const IDB_CONFIG = {
	DB_NAME: 'vvd-idb',
	DB_STORE: 'vvd-idb-store',
	DB_PERMISSIONS: 'readwrite'
}

const idb = window.indexedDB

let db
let dbstore

const createIDB = () => {
	const request = idb.open(IDB_CONFIG.DB_NAME, 1)
	createIDBStore(request)
}

const createIDBStore = (request) => {
	request.onupgradeneeded = (event) => {
		db = event.target.result
		dbstore = db.createObjectStore(IDB_CONFIG.DB_STORE, { keyPath: 'time', autoIncrement: true })
	}
}

export const IDBReady = () => {
	let exists = false

	return new Promise((resolve, reject) => {
		const req = indexedDB.open(IDB_CONFIG.DB_NAME, 1)
		req.onupgradeneeded = () => {
			req.transaction.abort()
			exists = true
			resolve(exists)
		}
		req.onsuccess = () => {
			exists = true
			resolve(exists)
		}
	})
}

export const setIDBData = (data = null) => {
	let request = idb.open(IDB_CONFIG.DB_NAME, 1)

	request.onsuccess = (event) => {
		try {
			if (!data) {
				return
			}

			db = event.target.result

			let transaction = db.transaction([IDB_CONFIG.DB_STORE], IDB_CONFIG.DB_PERMISSIONS)
			let objectStore = transaction.objectStore(IDB_CONFIG.DB_STORE) || dbstore

			const dataToStore = {
				data,
				time: new Date().getTime()
			}

			if (objectStore) {
				objectStore.add(dataToStore)

				// Handlers if we ever need them
				// objectStore.onsuccess = (ev) => {}
				// objectStore.onerror = (err) => {}
			}
		} catch (err) {}
	}
}

export const getIDBStore = (request) => {
	return dbstore
}

export const getIDBData = () => {
	return new Promise((resolve, reject) => {
		const request = idb.open(IDB_CONFIG.DB_NAME, 1)

		request.onsuccess = (event) => {
			try {
				db = event.target.result

				let transaction = db.transaction([IDB_CONFIG.DB_STORE], IDB_CONFIG.DB_PERMISSIONS)
				let objectStore = transaction.objectStore(IDB_CONFIG.DB_STORE) || dbstore

				if (objectStore) {
					const dataRequest = objectStore.getAll()

					dataRequest.onsuccess = (ev) => {
						const data = ev.target.result

						if (data?.length && data?.[0]) {
							resolve(data[0])
						} else {
							reject('no data')
						}
					}

					dataRequest.onerror = (err) => {
						reject(err)
					}
				} else {
					reject('no object store')
				}
			} catch (err) {
				reject(err)
			}
		}

		request.onerror = (err) => {
			reject(err)
		}
	})
}

export const initIDB = () => {
	createIDB()
}
