import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const campaignResource = createResource({
  endpoint: `${API_URL}/hub/dashboard/campaign-data`,
  auth: () => userStorage[USER_TOKEN].get()
})
// /hub/dashboard/voting-goal
/**
 *
 * Service for the user api.
 */
export const campaignService = {
  getCampaignData: () => {
    return campaignResource.get().json()
  }
}
