import React, { useEffect, useMemo } from 'react'
import { ContactPartijbureauCard, ContactCoordinatorCard } from '../card'
import { Column, Row } from 'simple-flexbox'
import styles from './contact.module.css'
import { createUseFetch } from '../../hooks/use-fetch'
import { contactService } from '../../services/contact.service'
import { LoadingIndicator} from '../../components'

const { getContacts: useGetContacts } = createUseFetch(contactService)

export const ContactOverview = () => {

  const [getContacts, loadingContacts, contacts ] = useGetContacts({onSuccess: res=> res?.data})

  useEffect(()=> {
    getContacts()
  },[])

  const mainContact = useMemo(()=>{
    if (!contacts || !contacts.length) return null
    return contacts.find(c => c.is_main_contact)
  },[contacts])

  const normalContacts = useMemo(()=>{
    if (!contacts || !contacts.length) return null
    return contacts.filter(c => !c.is_main_contact).sort((a, b)=> a.description.localeCompare(b.description))
  },[contacts])

	return (
		<div>
			<div className={styles.header}>
				<h2>Contact</h2>
			</div>
      <LoadingIndicator loading={loadingContacts} title={'Loading contacts'}>
			<Column wrap>
				<Row className={`gapL`} wrap alignItems={'start'} vertical="stretch">
					<Column wrap flexGrow={1} flexBasis={'50%'}>
						<ContactCoordinatorCard contacts={normalContacts}/>
					</Column>
					<Column wrap flexGrow={1} flexBasis={'40%'}>
						<ContactPartijbureauCard contactInfo={mainContact}/>
					</Column>
				</Row>
			</Column>
      
      </LoadingIndicator>
		</div>
	)
}
