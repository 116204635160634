.errormessage {
  display: block;
  font-size: var(--size-small);
  max-width: 18rem;
  margin-bottom: 16px;
  color: var(--colors-red);
  word-wrap: break-word;
  hyphens: auto;
}

.successmessage {
  margin-top: 16px;
  color: var(--colors-orange);
}
