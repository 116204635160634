import React from 'react'
import { authenticationReducer, errorReducer, login, logout } from '../../state'
import { createUseReducerState, fromReducer, withReducerState, combineReducers } from '../../utils'

const auth = fromReducer(combineReducers({ auth: authenticationReducer }, { error: errorReducer }))

export const withAuth = withReducerState(
  (state) => {
    return { ...state.auth, ...state.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      login: (...args) => dispatch(login(...args)),
      logout: () => dispatch(logout())
    }
  }
)(auth)

export const withAuthErrors = withReducerState((state) => {
  return { ...state.error }
})(auth)

export const withUser = withReducerState((state) => {
  return { user: state.auth.user, subtitle: state.auth.subtitle, role: state.auth.role }
})(auth)

export const useAuthState = createUseReducerState(
  (state) => {
    return { ...state.auth, ...state.errors }
  },
  (dispatch) => {
    return {
      login: () => dispatch(login())
    }
  }
)(auth)
