export const newsConstants = {
  NEWS_START: 'NEWS_START',
  NEWS_START_ADDING: 'NEWS_START_ADDING',
  NEWS_END: 'NEWS_END',
  NEWS_END_ADDING: 'NEWS_END_ADDING',

  NEWS_LIST_ADDED: 'NEWS_LIST_ADDED',
  NEWS_LIST_SUCCESS: 'NEWS_LIST_SUCCESS',
  NEWS_LIST_FAILURE: 'NEWS_LIST_FAILURE',
  NEWS_UPDATE_META: 'NEWS_UPDATE_META',
  FEATURED_LIST_SUCCESS: 'FEATURED_LIST_SUCCESS',

  NEWS_ITEM_GET_SUCCESS: 'NEWS_ITEM_GET_SUCCESS',
  NEWS_ITEM_GET_FAILURE: 'NEWS_ITEM_GET_FAILURE',

  FAILURE: 'FAILURE'
}
