import React from 'react'
import { withAuth } from '../hoc'
import styles from './app-layout.module.css'

export const AppLayout = withAuth(({ user, navigation, children }) => {
  return (
    (user && (
      <div className={styles.wrapper}>
        <nav className={styles.nav}>{navigation}</nav>
        <div className={styles.main}>
          <div className={styles.contentAuth}>{children}</div>
        </div>
      </div>
    )) || <div className={styles.content}>{children}</div>
  )
})
