import { canvasConstants, geocodeConstants, questionnaireConstants } from '../constants'

export const initialCanvasState = {
  list: [],
  resourceComing: {
    list: [],
    meta: {
      per_page: null,
      current_page: 1,
      total: 0
    },
  },
  resourceFinished: {
    list: [],
    meta: {
      per_page: null,
      current_page: 1,
      total: 0
    },
  },
  selectedAction: null,
  loading: false,
  busy: false,
  loadingDelete: false,
  places: [],
  deletedActionId: null,
  questionnaires: []
}
const reducerSwitch = {
  [canvasConstants.ACTIONS_START]: (state) => ({
    ...state,
    deletedActionId: null,
    loading: true
  }),
  [canvasConstants.ACTIONS_BUSY]: (state) => ({
    ...state,
    deletedActionId: null,
    loading: false,
    busy: true
  }),
  [canvasConstants.ACTIONS_END]: (state) => ({
    ...state,
    loadingDelete: false,
    busy: false,
    loading: false
  }),
  [canvasConstants.ACTIONS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedAction: null,
    list: payload
  }),
  [canvasConstants.ACTIONS_FINISHED_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedAction: null,
    resourceFinished: {
      list: payload.data || state.resourceFinished.list,
      meta: payload.meta
    }
  }),
  [canvasConstants.ACTIONS_COMING_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedAction: null,
    resourceComing: {
      list: payload.data || state.resourceComing.list,
      meta: payload.meta
    }
  }),
  [canvasConstants.ACTIONS_LIST_ADDED]: (state, { payload }) => ({
    ...state,
    selectedAction: null,
    [payload.resourceType]: {
      list: [...state[payload.resourceType].list, ...payload.data],
      meta: payload.meta
    }
  })
  ,
  [questionnaireConstants.QUESTIONNAIRES_SUCCESS]: (state, { payload }) => ({
    ...state,
    questionnaires: payload
  }),
  [geocodeConstants.FIND_ADDRESS_SUCCESS]: (state, { payload }) => ({
    ...state,
    hits: payload
  }),
  [canvasConstants.ACTION_GET_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedAction: {
      ...payload
    }
  }),
  [canvasConstants.ACTION_SAVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: [...state.list, payload]
  }),
  [canvasConstants.ACTION_UPDATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedAction: { ...payload }
  }),

  [canvasConstants.ACTION_DELETE_START]: (state) => {
    return { ...state, loadingDelete: true }
  },

  [canvasConstants.ACTION_DELETE_SUCCESS]: (state) => {
    return { ...state, deletedActionId: state.selectedAction?.id, selectedAction: null }
  }
}

export const canvasReducer = (state = initialCanvasState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return initialCanvasState
  }
}
