import { campaignConstants } from '../constants'

export const initialCampaignState = {
  loading: false,
  kickstarter: null,
  campaign_data: null,
  voting_goal: null,
  meat_balls: null
}
const reducerSwitch = {
  [campaignConstants.CAMPAIGN_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    ...payload
  }),
  [campaignConstants.CAMPAIGN_REQUEST]: (state) => ({ ...state, loading: true }),
  [campaignConstants.CAMPAIGN_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload
  })
}

export const campaignReducer = (state = initialCampaignState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return state
  }
}
