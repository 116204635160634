import React from 'react'
import styles from './form.module.css'

const FormInputLabel = ({ htmlFor, label, classes, children }) => {
  return (
    label && (
      <label htmlFor={htmlFor} className={`${classes ? classes : ''} ${styles.label}`}>
        {children ? children : label}
      </label>
    )
  )
}
export { FormInputLabel }
