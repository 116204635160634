import {
  USER_TOKEN,
  USER_NAME,
  USER_TOKEN_EXPIRES_IN,
  USER_SUBTITLE,
  USER_ROLE
} from '../../constants'
import { authService } from '../../services'
import { userStorage } from '../../utils'
import { authConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })

const request = action(authConstants.LOGIN_REQUEST)
const success = action(authConstants.LOGIN_SUCCESS)
const failure = action(authConstants.LOGIN_FAILURE)
const logoutSuccess = action(authConstants.LOGOUT_SUCCESS)

const login =
  ({ email, password }) =>
  (dispatch, getState) => {
    dispatch(request({ email }))
    authService
      .loginUser({ email, password })
      .then((response) => {
        const { name, access_token, subtitle, expires_in, user_role: role } = response.data
        userStorage[USER_NAME].save(name)
        userStorage[USER_TOKEN].save(access_token)
        userStorage[USER_TOKEN_EXPIRES_IN].save(expires_in)
        userStorage[USER_ROLE].save(role)
        userStorage[USER_SUBTITLE].save(subtitle),
          dispatch(success({ name, expires_in, access_token, role, subtitle }))
      })
      .catch((err) => {
        dispatch(failure(JSON.parse(err.message)))
      })
  }

const logout = () => (dispatch, getState) => {
  userStorage[USER_NAME].remove()
  userStorage[USER_TOKEN].remove()
  userStorage[USER_TOKEN_EXPIRES_IN].remove()
  userStorage[USER_ROLE].remove()
  userStorage[USER_SUBTITLE].remove()
  // One-time remove deprecated key
  localStorage.removeItem('USER_MUNICIPALITIES')

  if ('indexedDB' in window) {
    window.indexedDB.deleteDatabase('vvd-idb')
  }

  dispatch(logoutSuccess())
}

export { login, logout }
