import { nanoid } from 'nanoid'

export const contactInfoCoordinator = [
  { id: nanoid(), area: 'Noord-Holland', email: 'noordholland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Zuid-Holland', email: 'zuidholland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Groningen', email: 'groningen@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Friesland', email: 'friesland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Drenthe', email: 'drenthe@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Utrecht', email: 'utrecht@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Overijssel', email: 'overijssel@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Flevoland', email: 'flevoland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Gelderland', email: 'gelderland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Zeeland', email: 'zeeland@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Noord-Brabant', email: 'noordbrabant@vvd.nl', phone: '+31612345678' },
  { id: nanoid(), area: 'Limburg', email: 'limburg@vvd.nl', phone: '+31612345678' }
]

export const contactInfoPartijbureau = {
  id: nanoid(),
  firstName: 'Albert',
  lastName: 'Tuit',
  email: 'a.tuit@vvd.nl',
  campageEmail: 'campagne@vvd.nl',
  campagneTeam: 'Campagneteam',
  phone: '070-361 3033',
  hours: 'Ma t/m vrij - 09:00 17:00',
  whatsapp: `Scan de QR code.`
}
