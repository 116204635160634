import { canvasService, geocodeService } from '../../services'
import { canvasConstants, questionnaireConstants, geocodeConstants } from '../constants'

const action = (actionType, payload) => ({ type: actionType, payload })
const failure = ({ message }) => action(canvasConstants.FAILURE, JSON.parse(message))
const export_start = action(canvasConstants.CAMPAIGN_EXPORT_START)
const export_end = action(canvasConstants.CAMPAIGN_EXPORT_END)

const listSeperatedActions =
  ({ coming_per_page, coming_page, finished_per_page, finished_page }) =>
  (dispatch, getState) => {
    dispatch(action(canvasConstants.ACTIONS_START))

    Promise.all([
      canvasService.listActions({ per_page: coming_per_page, is_past: 0 }),
      canvasService.listActions({ per_page: finished_per_page, is_past: 1 })
    ])
      .then(([comingList, finishedList]) => {
        dispatch(action(canvasConstants.ACTIONS_COMING_LIST_SUCCESS, comingList))
        dispatch(action(canvasConstants.ACTIONS_FINISHED_LIST_SUCCESS, finishedList))
        // Overwrite server pagination to continue history pagination
        if (coming_page) {
          dispatch(
            action(canvasConstants.ACTIONS_COMING_LIST_SUCCESS, {
              meta: {
                per_page: comingList.meta.per_page / coming_page,
                current_page: coming_page
              }
            })
          )
        }
        if (finished_page) {
          dispatch(
            action(canvasConstants.ACTIONS_FINISHED_LIST_SUCCESS, {
              meta: {
                per_page: comingList.meta.per_page / finished_page,
                current_page: finished_page
              }
            })
          )
        }
      })
      .catch((err) => dispatch(failure(err)))
      .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
  }

const getNextSeperatedActions = (resourceType) => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_START))
  const {
    actions: {
      [resourceType]: { meta }
    }
  } = getState()
  const page = meta.current_page + 1
  const is_past = +(resourceType === 'resourceFinished')
  canvasService
    .listActions({ is_past, page })
    .then((response) =>
      dispatch(action(canvasConstants.ACTIONS_LIST_ADDED, { resourceType, ...response }))
    )
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
}

const listAllActions = () => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_START))
  canvasService
    .listActions()
    .then((response) => dispatch(action(canvasConstants.ACTIONS_LIST_SUCCESS, response.data)))
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
}
const getActionById =
  ({ id }) =>
  (dispatch, getState) => {
    dispatch(action(canvasConstants.ACTIONS_START))
    canvasService
      .getAction(id)
      .then((response) => dispatch(action(canvasConstants.ACTION_GET_SUCCESS, response.data)))
      .catch((err) => dispatch(failure(err)))
      .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
  }

const saveAction = (actionData, callback) => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_BUSY))
  canvasService
    .saveAction({ ...actionData })
    .then((response) => {
      dispatch(action(canvasConstants.ACTION_SAVE_SUCCESS, response.data))
      callback()
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => {
      dispatch(action(canvasConstants.ACTIONS_END))
    })
}

const updateAction = (id, actionData, callback) => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_BUSY))
  canvasService
    .updateAction(id, { ...actionData })
    .then((response) => {
      dispatch(action(canvasConstants.ACTION_UPDATE_SUCCESS, response?.data))
      callback()
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => {
      dispatch(action(canvasConstants.ACTIONS_END))
    })
}

const deleteAction = (id) => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_BUSY))
  canvasService
    .deleteAction(id)
    .then(() => {
      dispatch(action(canvasConstants.ACTION_DELETE_SUCCESS))
    })
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
}

const getQuestionnaires = () => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_START))
  canvasService
    .getQuestionnaires()
    .then((response) => {
      dispatch(action(questionnaireConstants.QUESTIONNAIRES_SUCCESS, response.data))
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
}

const exportActionData = (id) => (dispatch, getState) => {
  dispatch(export_start)
  canvasService
    .exportActionData(id)
    .then((response) => dispatch(action(canvasConstants.CAMPAIGN_EXPORT_SUCCESS, response)))
    .finally(() => dispatch(export_end))
}

const findAddress = (searchText) => (dispatch, getState) => {
  dispatch(action(canvasConstants.ACTIONS_START))
  geocodeService
    .find(searchText)
    .then((response) => {
      dispatch(action(geocodeConstants.FIND_ADDRESS_SUCCESS, response.features))
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => dispatch(action(canvasConstants.ACTIONS_END)))
}

export {
  listAllActions,
  listSeperatedActions,
  getNextSeperatedActions,
  getActionById,
  saveAction,
  updateAction,
  deleteAction,
  getQuestionnaires,
  exportActionData,
  findAddress
}
