import React, { useCallback, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { Button } from '../../core'
import styles from './modal.module.css'
Modal.setAppElement('#container')

export const VVDModal = ({
  title,
  content,
  footer,
  isOpen,
  onClose,
  closeIcon,
  closeAfterLoadingReady = true,
  isLoading = false,
  closeOnClickOutside = true,
  modalClass,
  titleClass
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen)
  const loadingRef = useRef()
  const closeModal = useCallback(() => {
    setIsOpenInternal(false)
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }, [setIsOpenInternal, onClose])

  useEffect(() => {
    setIsOpenInternal(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!loadingRef.current && isLoading && isOpen) {
      loadingRef.current = isLoading
      // was not loading, now is...
    }
    if (!isLoading && loadingRef?.current && isOpen) {
      // was loading, now stopped...
      loadingRef.current = false
      if (closeAfterLoadingReady) {
        onClose()
      }
    }
  }, [isLoading, isOpen, onClose, closeAfterLoadingReady])

  return (
    <Modal
      shouldCloseOnOverlayClick={closeOnClickOutside}
      {...(closeOnClickOutside && {
        onRequestClose: closeModal
      })}
      isOpen={isOpenInternal}
      className={`${styles.modal} ${(modalClass && modalClass) || ''}`}
      overlayClassName={styles.overlay}
    >
      {closeIcon && (
        <div className={`${styles.closeIcon} ${styles.sticky}`}>
          <Button.Icon onClick={onClose} icon={closeIcon} size={'1.2rem'} color={'black'} />
        </div>
      )}
      <div
        className={`${(titleClass && titleClass) || styles.title}  ${
          isLoading ? 'loadingIndicator' : ''
        }`}
      >
        {title}
      </div>

      <div className={`${isLoading ? styles.disabled : ''} ${styles.content}`}>{content}</div>
      {footer && (
        <div className={`${isLoading ? styles.disabled : ''} ${styles.footer}`}>{footer}</div>
      )}
    </Modal>
  )
}
