import React from 'react'
import { contactInfoPartijbureau } from '../../../constants'
import { Card, Text, LinkButton } from '../../../core'
import QrCode from '../../../assets/images/qr.png'
import styles from './contact-partijbureau.module.css'

export const ContactPartijbureauCard = ({ classes, contactInfo }) => {
	return (
		(contactInfo && (
			<Card classes={`${classes ? classes : ''}`}>
				<Text.Large classes={`semibold margin-bottom-24`} color={'black'}>
					Algemene vragen aan het partijbureau{' '}
				</Text.Large>
				{/* <Text.SemiLarge classes={`bold ${styles.card}`}>Direct contact over CampageHub</Text.SemiLarge> */}
				<Text.SemiLarge classes={`margin-bottom-24 ${styles.card}`}>
					Vragen over de campagne hub of het doorvoeren van wijzigingen in het systeem kun je aan het
					partijbureau stellen.
				</Text.SemiLarge>

				<Text.SemiLarge classes={`semibold ${styles.card}`}>{contactInfo.description}</Text.SemiLarge>
				<Text.SemiLarge>
					<LinkButton.External
						url={`mailto:${contactInfo.email}`}
						classes={`${styles.card} ${styles.link}`}
						color={'blue'}
					>
						{contactInfo.email}
					</LinkButton.External>
				</Text.SemiLarge>
				{/* <Text.SemiLarge classes={`semibold ${styles.card}`} color={'black'}>
					{contactInfoPartijbureau.campagneTeam}
				</Text.SemiLarge> */}
				<Text.SemiLarge classes={`margin-bottom-24 ${styles.card}`}>{contactInfo.phone}</Text.SemiLarge>
				<Text.Medium classes={`margin-bottom-8 ${styles.card}`}>
					Zoom iedere woensdagavond om 19.00 uur met het campagneteam
				</Text.Medium>
				<Text.SemiLarge classes={`semibold ${styles.card}`}>
					<LinkButton.External
						color="blue"
						classes={`margin-bottom-16 ${styles.card} ${styles.link}`}
						url={'https://zoom.us/j/96251855613?pwd=bi9BejVjSkFwT2JHUEt2Vy93ejJWQT09'}
					>
						Sluit aan bij onze Zoom meeting
					</LinkButton.External>
				</Text.SemiLarge>
				<Text.Medium classes={`margin-bottom-8 ${styles.card}`}>
					Bij vragen, neem contact op. Dagelijks en in het weekend zit het campagneteam voor je klaar.
					Telefonisch bereikbaar via 070-361 3033
				</Text.Medium>
				<Text.Medium classes={`margin-bottom-8 ${styles.card}`}>
					Het meest eenvoudig te Whatsappen via 06-82 78 87 38. Of scan daarvoor de QR-code:
				</Text.Medium>
				<img className={`margin-top-8 ${styles.qrcode}`} src={QrCode} />
			</Card>
		)) ||
		null
	)
}
