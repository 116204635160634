import React, { memo, useMemo, useRef } from 'react'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { MapContainer, TileLayer, GeoJSON, Tooltip, FeatureGroup } from 'react-leaflet'

import styles from './map.module.css'

export const Map = memo(
  ({
    locationData = null,
    locationTime = null,
    reverse = true,
    center = undefined,
    zoom = 13,
    shapeOpacity = 0.7,
    children,
    classes
  }) => {
    const $map = useRef(null)

    // const colorOptions = { Oranje: '#FF6400', Blauw: '#0A2CCA' }
    const colorOptions = {
      phase1: { label: 'Fase 1', color: '#118DFF' },
      phase2: { label: 'Fase 2', color: '#E66C37' },
      phase3: { label: 'Fase 3', color: '#009688' },
      null: { label: null, color: 'rgba(0,0,0,0)' }
    }

    // Oranje, Blauw
    const findColor = (input) => {
      if (colorOptions?.[input]) return colorOptions?.[input]?.color
      else return 'rgba(0,0,0,0)'
    }

    const findOpacity = (priority = 7) => {
      return priority / 10
    }

    const renderFeatureGroups = useMemo(() => {
      if (!locationData?.features?.length) return null

      const collection = locationData?.features
      const len = collection.length
      let n = 0
      let result = []

      for (n; n < len; n++) {
        const item = collection[n]
        const { properties } = item
        const opacity = findOpacity(properties.priority) || shapeOpacity
        const color = findColor(properties.color) || 'rgba(0,0,0,0)'

        const object = (
          <FeatureGroup key={n}>
            <GeoJSON
              key={n}
              pathOptions={{ color }}
              style={{ fillOpacity: opacity, weight: 1 }}
              data={item}
            />
            <Tooltip sticky>Postcode: {properties.PC6}</Tooltip>
          </FeatureGroup>
        )
        result.push(object)
      }

      return result
    }, [locationData, locationData?.features, findOpacity, findColor])

    const getMapContainerCenter = useMemo(() => {
      if (!locationData?.features?.length) return null

      let coords = locationData.features[0].geometry?.coordinates[0][0][0]
      if (reverse) coords.reverse()

      return { lat: coords[0], lng: coords[1] }
    }, [locationData, locationData?.features, reverse])

    return locationData?.features && locationData?.features?.length ? (
      <MapContainer
        className={`${styles.map} ${classes ? classes : ''}`}
        center={getMapContainerCenter}
        zoom={zoom}
        scrollWheelZoom={false}
        id={`ac-map-container--${locationTime}--lat-${getMapContainerCenter?.lat}--lng-${getMapContainerCenter?.lng}`}
        data-coords-lat={getMapContainerCenter?.lat}
        data-coords-lng={getMapContainerCenter?.lng}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
          crossOrigin='null'
        />
        {renderFeatureGroups}
        {children}
      </MapContainer>
    ) : null
  }
)
