import { LinkButton } from '../../display'

import React from 'react'
import styles from './tabs.module.css'

export const Tab = ({ title, animate, selected, onSelectTab }) => {
  return (
    <div className={'hcenter'}>
      <LinkButton
        url={''}
        direct
        onClick={(e) => {
          e.preventDefault()
          onSelectTab()
        }}
        classes={`${styles.tab} inlineflex ${selected ? styles.selected : ''} ${
          animate && styles.animate
        }`}
      >
        {title}
      </LinkButton>
    </div>
  )
}
