import { campaignReducer, errorReducer, getCampaignData } from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const actions = fromReducer(combineReducers({ campaign: campaignReducer }, { error: errorReducer }))

export const withCampaign = withReducerState(
  (state) => {
    return {
      ...state?.campaign
    }
  },
  (dispatch) => {
    return {
      getCampaign: () => dispatch(getCampaignData())
    }
  }
)(actions)

export const withCampaignErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(actions)
