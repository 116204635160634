import React from 'react'
import styles from './footer.module.css'

export const Footer = ({ left, right, children }) => {
  return (
    <div className={styles.footer}>
      {left && <div className={styles.left}>{left}</div>}
      {right && <div className={styles.right}>{right}</div>}
      {children}
    </div>
  )
}
