import { createResource } from '.'
import { API_URL } from '../constants'

const accountResource = createResource({
  endpoint: `${API_URL}/hub`,
  options: { mode: 'cors' }
})

/**
 *
 * Service for the account api.
 */
export const accountService = {
  resetPassword: (formData) => {
    return accountResource.url('reset-password').post(formData).json()
  },
  forgotPassword: (formData) => {
    return accountResource.url('forgot-password').post(formData).json()
  }
}
