import { newsConstants } from '../constants'

export const initialNewsState = {
  list: [],
  featuredList: [],
  meta: {
    per_page: null,
    current_page: 1,
    total: 0
  },
  selectedNewsItem: null,
  loading: false,
  skeleton: false
}
const reducerSwitch = {
  [newsConstants.NEWS_START]: (state) => ({
    ...state,
    loading: true
  }),
  [newsConstants.NEWS_START_ADDING]: (state) => ({
    ...state,
    skeleton: true
  }),
  [newsConstants.NEWS_END_ADDING]: (state) => ({
    ...state,
    skeleton: false
  }),
  [newsConstants.NEWS_END]: (state) => ({
    ...state,
    loading: false
  }),
  [newsConstants.NEWS_UPDATE_META]: (state, { payload }) => ({
    ...state,
    meta: { ...state.meta, ...payload }
  }),
  [newsConstants.NEWS_LIST_ADDED]: (state, { payload: { data, meta } }) => ({
    ...state,
    selectedNewsItem: null,
    list: [...state.list, ...data],
    meta
  }),
  [newsConstants.NEWS_LIST_SUCCESS]: (state, { payload: { data, meta } }) => ({
    ...state,
    selectedNewsItem: null,
    list: data,
    meta
  }),
  [newsConstants.FEATURED_LIST_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    selectedNewsItem: null,
    featuredList: data
  }),
  [newsConstants.NEWS_ITEM_GET_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedNewsItem: { ...payload }
  })
}

export const newsReducer = (state = initialNewsState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return initialNewsState
  }
}
