import { useRef, useCallback, useState, useEffect } from 'react'

/**
 * React custom hook.
 * This hook manages click events on the document and the target component.
 * When clicked outside the target, the visible state changes.
 * @param {*} isVisible
 */
export const useClickOutside = (isVisible) => {
  const [element, setElement] = useState()
  const [visible, setVisible] = useState(isVisible)
  const ref = useRef()

  const handleRef = useCallback((node) => {
    setElement(node)
  }, [])

  const handleClick = (e) => {
    const target = e.target
    if (ref.current) {
      if (!ref.current.contains(target)) {
        setVisible(false)
      }
    }
  }

  useEffect(() => {
    if (element) {
      ref.current = element
      document.addEventListener('click', handleClick, true)
    }
    return () => {
      document.removeEventListener('click', handleClick, true)
      ref.current = null
    }
  }, [element])

  return {
    setRef: handleRef,
    visible,
    setVisible
  }
}
