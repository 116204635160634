import { chiliConstants } from '../constants'

const initialState = {
  loading: false,
  chiliApiKey: null,
  data: null
}
const reducerSwitch = {
  [chiliConstants.PROFILE_SUCCESS]: (state, { payload }) => ({
    ...state,
    chiliApiKey: payload,
    loading: false
  }),
  [chiliConstants.PROFILE_REQUEST]: (state) => ({ ...state, loading: true }),
  [chiliConstants.PROFILE_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload
  })
}

export const chiliReducer = (state = initialState, action) => {
  return reducerSwitch[action.type] ? reducerSwitch[action.type](state, action) : state
}
