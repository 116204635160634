import React, { useCallback, useEffect, useState } from 'react'
import { Column, Row } from 'simple-flexbox'
import { Card } from '../../core'
import styles from './municipality.overview.module.css'

import { Card, Text } from '../../core'

import { municipalityOverviewService } from '../../services'

export const MunicipalityOverview = ({ getMapData, loadingMapData, mapData }) => {
  const [municipalities, setMunicipalities] = useState([])
  useEffect(() => {
    municipalityOverviewService
      .getMunicipalityOverview()
      .then((response) => {
        setMunicipalities(response.data)
      })
  }, [])

  const meatballGenerator = useCallback((score, target) => {
    const count = Math.round(score / target * 10)
    const meatballs = []
    for (let i = 0; i < 10; i++) {
      meatballs.push(
        <div key={i} className={styles.meatball} data-filled={i < count}></div>
      )
    }
    return <div className={styles.meatballProgressor}>{meatballs}</div>
  }, [municipalities])

  return (
    <Card style={{ width: '100%' }}>
      <Row>
        <Column flexGrow={2}>
          <Text.SemiLarge classes={'bold'} color={'black'}>Gemeentes</Text.SemiLarge>
        </Column>
      </Row>
      <table className={styles.overviewTable}>
        <thead>
          <tr>
            <td>Gemeentes</td>
            <td>Voting goal</td>
            <td>Aantal vrijwilligers</td>
            <td>Gesprekken gevoerd</td>
            <td colSpan={2}>Bitterballen</td>
          </tr>
        </thead>
        <tbody>
          {municipalities.map((municipality, key) => {
            return (<tr key={key}>
              <td className='bold' style={{width: '150px'}}>{municipality.name}</td>
              <td>{municipality.voting_goal || '-'}</td>
              <td>{municipality.volunteers_count}</td>
              <td>
                <span className={styles.scoreWrapper}>
                  <strong>{municipality.conversations_count}</strong> / &ensp;{municipality.conversation_target || '-'}
                </span>
              </td>
              <td>
                <span className={styles.scoreWrapper}>
                  <strong>{municipality.meatball_score}</strong> / &ensp;{municipality.meatball_target || '-'}
                </span>
              </td>
              <td style={{ width: '220px' }}>
                {meatballGenerator(municipality.meatball_score, municipality.meatball_target)}
              </td>
            </tr>)
          })}
        </tbody>
      </table>
    </Card>
  )
}
