import React, { forwardRef, useEffect, useState } from 'react'
import styles from './form.module.css'

export const TextFieldAdornment = forwardRef(
  ({ classes, position, hideWhen, disablePointerEvents = false, children, ...rest }, ref) => {
    const [shouldHide, setShouldHide] = useState(hideWhen || false)

    useEffect(() => {
      setShouldHide(hideWhen)
    }, [hideWhen])

    return (
      <div
        ref={ref}
        className={`${disablePointerEvents ? styles.disabled : ''}${
          shouldHide ? styles.autoHide : ''
        }`}
        {...rest}
      >
        {children}
      </div>
    )
  }
)
