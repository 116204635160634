import React, { useMemo } from 'react'
import { Text, Card, Button } from '../../../core'
import { Column, Row } from 'simple-flexbox'
import { FiHelpCircle } from 'react-icons/fi'
import { MeatballStack } from '../../dashboard/components/meatballs.stack'
import { formatNumber } from '../../../utils'
import VVDArrow from '../../../assets/images/vvd-pijl.svg'
import { PROVINCIAL_CAMPAIGNMANAGER } from '../../../constants'
import { withAuth } from '../../hoc'

export const VotingGoalCard = withAuth(({
  onInfoClick,
  onViewVotingGoal,
  votingGoal,
  meatballsCollected,
  meatballsTarget,
  role
}) => {
  const numberOfMeatballsToFill = useMemo(() => {
    const oneBallRepresentative = Math.floor(Number(meatballsTarget) / 10)
    const fill = Math.floor(meatballsCollected / oneBallRepresentative)
    return fill
  }, [meatballsTarget, meatballsCollected])

  return (
    <Card
      style={{ justifyContent: 'normal', flexGrow: 1 }}
      classes={`flexcenter tpad bpad lpad rpad nopadding gapL hgrow-0`}
    >
      <Column className={'gapL'} flexGrow={1} justifyContent='space-between'>
        {role === PROVINCIAL_CAMPAIGNMANAGER && !votingGoal && (
          <Row>
            <Column flexGrow={2}>
              <Button.Primary onClick={onViewVotingGoal}>Stel een Provincie Voting goal in
                <VVDArrow />
              </Button.Primary>
            </Column>
          </Row>)
        }
        <Row>
          <Column flexGrow={2}>
            <Text.XXL
              classes={'tpad semibold'}
              color={`${votingGoal && votingGoal > 0 ? 'blue' : 'lightgray'}`}
            >
              {formatNumber(votingGoal) || 0}
            </Text.XXL>

            <Text.Medium classes={'hcenter tpad semibold'} color={'black'}>
              Voting goal
              <Button.Icon
                icon={
                  <FiHelpCircle
                    style={{ marginBottom: '2px' }}
                    onClick={onInfoClick('voting')}
                    size={'1.3rem'}
                    color={'white'}
                    fill={'gray'}
                  />
                }
              />
              {votingGoal && votingGoal > 0 && (
                <Button.Link onClick={onViewVotingGoal}>Bekijk berekening</Button.Link>
              )}
            </Text.Medium>
          </Column>
        </Row>
        <Row flexGrow={0} alignItems='end' justifyContent='space-between'>
          <Column flexGrow={0}>
            <Row className={'gapXs'}>
              <Text.XXL
                classes={'semibold'}
                color={`${!meatballsCollected ? 'lightgray' : 'black'}`}
              >
                {formatNumber(meatballsCollected) || 0}
              </Text.XXL>

              <Text.Medium
                classes={'semibold'}
                color={`${!meatballsTarget ? 'lightgray' : 'darkgray'}`}
              >
                /
              </Text.Medium>
              <Text.Medium
                classes={'semibold'}
                color={`${!meatballsTarget ? 'lightgray' : 'darkgray'}`}
              >
                {formatNumber(meatballsTarget) || 0}
              </Text.Medium>
            </Row>
            <Text.Medium classes={'tpad semibold'} color={'black'}>
              Bitterballenschaal
              <Button.Icon
                icon={
                  <FiHelpCircle
                    style={{ marginBottom: '2px' }}
                    onClick={onInfoClick('meatballs')}
                    size={'1.3rem'}
                    color={'white'}
                    fill={'gray'}
                  />
                }
              />
            </Text.Medium>
          </Column>
          <Column alignItems='end' justifyContent={'end'} style={{ marginBottom: '1px' }}>
            <MeatballStack total={10} filled={numberOfMeatballsToFill} />
          </Column>
        </Row>
      </Column>
    </Card>
  )
})