.pinInput {
  display: flex;
  width: var(--size-large);
  flex-grow: 0;
  font-weight: 200;
  text-align: center;
  align-items: center;
  font-size: var(--size-medium);
}
.pinInput input::-webkit-inner-spin-button,
.pinInput input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pinInput {
  -moz-appearance: textfield;
}
.pinInput input {
  flex-grow: 0;
  text-align: center;
  overflow: hidden;
}

.pincode {
  flex-grow: 1;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
