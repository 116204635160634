import { newsService } from '../../services'
import { newsConstants } from '../constants'

const action = (actionType, payload) => ({ type: actionType, payload })
const failure = ({ message }) => action(newsConstants.FAILURE, JSON.parse(message))

const listAllNewsItems = ({ per_page, page }) => (dispatch, getState) => {
  dispatch(action(newsConstants.NEWS_START))

  Promise.all(
    [newsService.listNewsItems({ per_page }),
    newsService.listNewsItems({ featured: true })]
  )
    .then(([newsList, featuredList]) => {
      dispatch(action(newsConstants.NEWS_LIST_SUCCESS, newsList))
      // Overwrite server pagination to continue history pagination
      if (page) {
        dispatch(action(newsConstants.NEWS_UPDATE_META, {
          per_page: newsList.meta.per_page / page, current_page: page
        }))
      }
      dispatch(action(newsConstants.FEATURED_LIST_SUCCESS, featuredList))
    })
    .catch(err => dispatch(failure(err)))
    .finally(() => dispatch(action(newsConstants.NEWS_END)))
}

const getNextNewsPage = () => (dispatch, getState) => {
  dispatch(action(newsConstants.NEWS_START_ADDING))
  const { newsItems: { meta } } = getState()
  const page = meta.current_page + 1
  newsService
    .addNewsItems(page)
    .then((response) => dispatch(action(newsConstants.NEWS_LIST_ADDED, response)))
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(newsConstants.NEWS_END_ADDING)))
}

const getNewsItemById =
  ({ id }) =>
    (dispatch, getState) => {
      dispatch(action(newsConstants.NEWS_START))
      newsService
        .getNewsItem(id)
        .then((response) => dispatch(action(newsConstants.NEWS_ITEM_GET_SUCCESS, response.data)))
        .catch((err) => dispatch(failure(err)))
        .finally(() => dispatch(action(newsConstants.NEWS_END)))
    }

export { listAllNewsItems, getNextNewsPage, getNewsItemById }
