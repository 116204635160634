import React from 'react'
import { Button } from '../../../core'
import { VVDModal } from '../vvd.modal'

export const TeamDeleteModal = ({
  cancel,
  confirm,
  title,
  onClose,
  children,
  warningMessage,
  buttonText,
  ...rest
}) => {
  return (
    <VVDModal
      closeOnClickOutside={true}
      title={title}
      footer={
        <>
          <Button.Secondary onClick={cancel}>Annuleren</Button.Secondary>
          <Button.Danger onClick={confirm}>{buttonText}</Button.Danger>
        </>
      }
      content={warningMessage}
      {...(rest && {
        ...rest,
        onClose
      })}
    >
      {children}
    </VVDModal>
  )
}
