import React, { useState, useCallback, useEffect, useMemo, Suspense, useRef } from 'react'
import { ErrorDisplay, Text } from '../../core'
import { LoadingIndicator, KickstarterCard, VotingGoalCard, CampaignStatsCard } from '../card'
import { withCampaign, withCampaignErrors, withAuth } from '../hoc'
import { useNavigation } from '../../hooks'
import { Column } from 'simple-flexbox'
import { InfoModal, VotingGoalModal } from '../modal'
import infoJson from './info.json'
import { uiStorage } from '../../utils'
import { CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER } from '../../constants'

const CalcVotingGoal = React.lazy(() => import('../voting/voting.goal.component'))

const CampaignErrors = withCampaignErrors(({ errors, errorMessage }) => {
  return <ErrorDisplay errors={errors?.campaign || errorMessage} />
})

const KickstartCampaignComponent = ({
  getCampaign,
  campaign_data,
  kickstarter,
  meat_balls,
  voting_goal: votingGoal,
  loading,
  role
}) => {
  const [modalIsOpen, setModalIsOpen] = useState({ info: false, voting: false })
  const [modalInfo, setModalInfo] = useState({ title: '', content: '' })
  const [kickstarterClosed, setKickstarterClosed] = useState(
    uiStorage.KICKSTARTER_CLOSED.get() === 'true'
  )
  const closedRef = useRef(kickstarterClosed)

  const { navigateWithQueryString } = useNavigation()

  const toggleInfoModal = useCallback(() => {
    setModalIsOpen((v) => ({ ...v, info: !v.info }))
  }, [setModalIsOpen])

  const toggleVotingModal = useCallback(() => {
    setModalIsOpen((v) => ({ ...v, voting: !v.voting }))
  }, [setModalIsOpen])

  const infoModalIsOpen = useMemo(() => {
    return !!modalIsOpen.info
  }, [modalIsOpen])

  const votingModalIsOpen = useMemo(() => {
    return !!modalIsOpen.voting
  }, [modalIsOpen])

  const { voting_goal, campaign_actions, team } = kickstarter || {}

  const gotoVotingGoal = useCallback(() => {
    toggleVotingModal()
  }, [])
  const gotoTeam = useCallback(() => {
    navigateWithQueryString({ path: '/team', params: { mode: 'add' } })
  }, [])

  const gotoAction = useCallback(() => {
    navigateWithQueryString({ path: '/actions', params: { mode: 'add' } })
  }, [])

  useEffect(() => {
    if (role === PROVINCIAL_CAMPAIGNMANAGER) closeKickstarter(true)
    else closeKickstarter(false)
  }, [role])

  useEffect(() => {
    getCampaign()
  }, [])

  const showInfoModal = useCallback(
    (modalType) => () => {
      if (modalType === 'voting') {
        setModalInfo({ title: 'Voting goal', content: <div>{infoJson.voting}</div> })
      } else {
        setModalInfo({ title: 'Bitterballenschaal', content: <div>{infoJson.meatballs}</div> })
      }
      toggleInfoModal()
    },
    [toggleInfoModal]
  )

  const kickstarterHasData = useMemo(() => {
    if (role === CAMPAIGN_MANAGER) return !!voting_goal && !!campaign_actions && !!team
    return true
  }, [voting_goal, campaign_actions, team])

  const closeKickstarter = useCallback(
    (value) => {
      uiStorage?.KICKSTARTER_CLOSED?.save(value)
      setKickstarterClosed(value)
    },
    [setKickstarterClosed]
  )

  useEffect(() => {
    if (kickstarterHasData && closedRef.current) {
      closeKickstarter(true)
    }
  }, [kickstarterHasData, closeKickstarter])

  return (
    <>
      <Column flexBasis={'65%'}>
        <CampaignErrors />
        <LoadingIndicator loading={loading} title={'Loading campagne data'}>
          {!loading && kickstarterClosed && kickstarterHasData && (
            <CampaignStatsCard data={campaign_data} />
          )}
          {!loading && !kickstarterClosed && (
            <KickstarterCard
              onCalcVotingGoal={gotoVotingGoal}
              onCreateTeam={gotoTeam}
              onCreateAction={gotoAction}
              isCloseable={kickstarterHasData}
              onClose={gotoTeam}
              votingGoal={voting_goal}
              campaignActions={campaign_actions}
              onCloseKickStarter={() => closeKickstarter(true)}
              team={team}
              title={
                <Text.SemiLarge classes={`semibold`} color={'white'}>
                  Kickstart je campagne
                </Text.SemiLarge>
              }
            />
          )}
        </LoadingIndicator>
      </Column>
      <Column flexGrow={1} style={{ flexFlow: 'row' }} justifyContent='space-between'>
        <LoadingIndicator loading={loading} title={'Loading voting goal'}>
          <VotingGoalCard
            onViewVotingGoal={gotoVotingGoal}
            onInfoClick={showInfoModal}
            votingGoal={votingGoal}
            meatballsCollected={campaign_data?.meat_balls}
            meatballsTarget={meat_balls}
          />
        </LoadingIndicator>
      </Column>

      <InfoModal
        title={modalInfo.title}
        isOpen={infoModalIsOpen}
        content={modalInfo.content}
        onClose={() => toggleInfoModal()}
        cancel={() => toggleInfoModal()}
      />
      <VotingGoalModal
        title={'Voting goal berekenen'}
        isOpen={votingModalIsOpen}
        content={({ setLoading, onClose, setTitle }) => (
          <Suspense fallback={'Loading...'}>
            <CalcVotingGoal
              afterSaveVotingGoal={getCampaign}
              setLoading={setLoading}
              setTitle={setTitle}
              onClose={onClose}
            />
          </Suspense>
        )}
        onClose={() => toggleVotingModal()}
        cancel={() => toggleVotingModal()}
      />
    </>
  )
}

export const KickstartCampaign = withAuth(withCampaign(KickstartCampaignComponent))
