import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const paramsToString = (params) => {
  let _paramsToString = '?'
  const fromEntries = Object.entries(params)
  const size = fromEntries.length - 1
  let idx = 0
  for (const [key, value] of fromEntries) {
    _paramsToString += `${key}=${value}${idx < size ? '&' : ''}`
    idx++
  }
  return _paramsToString
}

export const useNavigation = () => {
  const history = useHistory()
  const location = useLocation()

  const navigate = (url, state) =>
    history.push(url, typeof state === 'function' ? state(location.state) : state)
  const navigateAndReload = (url) => (window.location.href = url)
  const back = () => {
    history.goBack()
  }

  const navigateWithQueryString = ({ path, params }) =>
    history.push({
      pathname: path,
      search: paramsToString(params)
    })

  const replaceWithOriginalLocation = useCallback(
    (defaultPage) => {
      const { from } = location.state || {
          from: {
            pathname:
              location.pathname !== '/' && location.pathname !== '/login'
                ? location.pathname
                : defaultPage
          }
        } || {
          from: { pathname: defaultPage }
        }
      if (from?.pathname === '/') {
        navigate(defaultPage)
      } else {
        history.replace(from)
      }
    },
    [history, location.pathname, location.state]
  )

  const currentLocation = useMemo(() => {
    return (location && location.pathname) || ''
  }, [location])

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  // const fromQueryString = useCallback(
  //   (key) => {
  //     return searchParams.get(key)
  //   },
  //   [searchParams]
  // )
  const fromQueryString = useCallback(
    (key) => {
      return new URLSearchParams(location.search).get(key)
    },
    [location.search]
  )

  return {
    currentLocation,
    searchParams,
    fromQueryString,
    navigateAndReload,
    navigateWithQueryString,
    location,
    back,
    history,
    navigate,
    replaceWithOriginalLocation
  }
}
