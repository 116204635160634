import { USER_SUBTITLE, USER_NAME, USER_TOKEN, USER_TOKEN_EXPIRES_IN, USER_ROLE } from '../../constants'
import { userStorage } from '../../utils'
import { authConstants } from '../constants'

export const initialAuthState = {
  user: userStorage[USER_NAME].get(),
  subtitle: userStorage[USER_SUBTITLE].get(),
  token: userStorage[USER_TOKEN].get(),
  role: userStorage[USER_ROLE].get(),
  loading: false,
  tokenExpires: userStorage[USER_TOKEN_EXPIRES_IN].get()
}
const reducerSwitch = {
  [authConstants.LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    user: payload.name,
    subtitle: payload.subtitle,
    role: payload.role,
    token: payload.access_token,
    tokenExpires: payload.expires_in
  }),
  [authConstants.LOGIN_REQUEST]: (state) => ({ ...state, loading: true }),
  [authConstants.LOGIN_FAILURE]: (state) => ({
    ...state,
    loading: false
  }),

  [authConstants.LOGOUT_REQUEST]: (state) => ({ ...state, loading: true }),
  [authConstants.LOGOUT_SUCCESS]: (state) => ({
    ...state,
    user: null,
    subtitle: null,
    token: null,
    role: null,
    subtitle: null
  })
}

export const authenticationReducer = (state = initialAuthState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return initialAuthState
  }
}
