import React from 'react'
import { Button, Text } from '../../../core'
import { ActionCard } from './action.card'

export const EmptyActionsCard = ({ ...rest }) => {
  return (
    <ActionCard
      body={
        <>
          <Text.Medium classes={'light'} color={'darkgray'}>
            De kalender is nog leeg
          </Text.Medium>
          <Text.SemiLarge classes={'semibold'}>Er zijn geen canvasacties gepland</Text.SemiLarge>
          <Button.Secondary {...rest} classes={'hfit'}>
            Start je eerste canvasactie
          </Button.Secondary>
        </>
      }
    />
  )
}
