.text {
  color: var(--colors-black);
  font-size: var(--size-medium);
  margin-top: 0px;
  font-weight: 400;
  flex-grow: 0;
  line-height: var(--size-large);
  display: inline-flex;
}

.link {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  color: var(--colors-blue);
  font-size: var(--size-medium);
  flex-grow: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.link:hover {
  text-decoration: underline;
  text-decoration-color: inherit;
}

.xxl {
  font-size: var(--size-xxl);
}
.xl {
  font-size: var(--size-xlarge);
}

.large {
  font-size: var(--size-large);
}
.semilarge {
  font-size: var(--size-semilarge);
}
.medium {
  font-size: var(--size-medium);
}
.small {
  font-size: var(--size-small);
}

.blue {
  color: var(--colors-blue);
}
.orange {
  color: var(--colors-orange);
}
.black {
  color: var(--colors-black);
}
.lightgray {
  color: var(--colors-lightgray);
}
.midgray {
  color: var(--colors-midgray);
}
.darkgray {
  color: var(--colors-darkgray);
}
.white {
  color: #fff;
}

.loader {
  position: absolute;
  right: 100%;
  bottom: 0;
  left: 2px;
  border-radius: 6px;
  width: 0;
  height: 3px;
  background: var(--colors-blue);
  opacity: 0.65;
  animation: slide 1s linear infinite;
}
@keyframes slide {
  0% {
    left: 2px;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 2px;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
