.kickstarter {
  background-color: var(--colors-blue);
  color: #fff;
}

.meatball {
  transform: scale(0.6);
  margin-left: -6px;
  margin-top: -2px;
}
