import React from 'react'
import styles from './errors.module.css'

export const ErrorDisplay = ({ errors, success }) => {
  return errors ? (
    <span className={`${styles.errormessage} ${success ? styles.successmessage : ''}`}>
      {errors}
    </span>
  ) : null
}
