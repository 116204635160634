/* eslint-disable */
import { useCallback, useMemo, useReducer } from 'react'

const thunkDispatcher = (dispatch, getState) => (action) => {
  if (typeof action === 'function') {
    // action is the dispatcher function here.
    action(thunkDispatcher(dispatch, getState), getState)
  } else {
    dispatch(action)
  }
}

const compose = (...fns) => {
  const slices = {}
  const reducers = fns.slice().map((fn) => {
    const slice = Object.keys(fn)[0]
    const reducerFn = fn[slice]
    slices[reducerFn] = slice
    return reducerFn
  })
  const reducerKeys = Object.keys(fns)
  return (state = {}, action) => {
    const nextState = {}
    let hasChanged = false
    for (const reducer of reducers) {
      const reducerKey = slices[reducer]
      const prevSliceState = state[reducerKey]
      const nextSliceState = reducer(prevSliceState, action)
      nextState[reducerKey] = nextSliceState
      hasChanged = hasChanged || nextSliceState !== prevSliceState
    }
    hasChanged = hasChanged || Object.keys(state).length !== reducerKeys.length
    return hasChanged ? nextState : state
  }
}

/**
 * Combines multiple (slice) reducers into one `reducer` function.
 * @param  {...any} reducers
 * @returns A function that is composed of the given reducers.
 */
export const combineReducers = (...reducers) => compose(...reducers)

/**
 * `useThunkReducer` hook can be used to mimic redux-thunk middleware.
 * @param  {Function} reducer - Reducer function.
 * @param  {any} initialValue - initial value for useReducer state.
 */

export const useThunkReducer = new Proxy(() => {}, {
  apply(target, thisArg, args) {
    const [state, dispatch] = useReducer.apply(null, args)
    const getState = useCallback(() => {
      return state
    }, [state])

    const reducerApi = useMemo(() => {
      return [state, thunkDispatcher(dispatch, getState)]
    }, [state, dispatch])
    return reducerApi
  }
})
