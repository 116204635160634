import React from 'react'
import styles from './kickstart.card.module.css'
import { Button, Card, Text } from '../../../core'
import { Column, Row } from 'simple-flexbox'
import VVDArrow from '../../../assets/images/vvd-pijl.svg'
import { FiChevronRight, FiCheck } from 'react-icons/fi'

export const KickstarterCard = ({
  title,
  votingGoal,
  campaignActions,
  team,
  onCalcVotingGoal,
  onCreateTeam,
  onCreateAction,
  isCloseable,
  onCloseKickStarter
}) => {
  return (
    <Card
      classes={`${styles.kickstarter}
       flexcenter tpad bpad lpad rpad nopadding gapL hgrow-0`}
    >
      <Row>
        <Column>{title}</Column>
      </Row>
      <Row>
        <Column flexGrow={1}>
          <Button.Kickstart
            finished={!!votingGoal}
            onClick={onCalcVotingGoal}
            kickstartIcon={
              (!!votingGoal && <FiCheck size={'1.2rem'} color={'#fff'} />) || (
                <VVDArrow style={{ width: '18px', height: '16px' }} />
              )
            }
            title={'Bereken je voting goal'}
          >
            <>
              <FiChevronRight size={'1.3rem'} />
              <span>Start berekening</span>
            </>
          </Button.Kickstart>
        </Column>
      </Row>
      <Row>
        <Column flexGrow={1}>
          <Button.Kickstart
            finished={!!team}
            onClick={onCreateTeam}
            kickstartIcon={
              (!!team && <FiCheck size={'1.2rem'} color={'#fff'} />) || (
                <VVDArrow style={{ width: '18px', height: '16px' }} />
              )
            }
            title={'Vul je team aan'}
          >
            <>
              <FiChevronRight size={'1.3rem'} />
              <span>Maak een team</span>
            </>
          </Button.Kickstart>
        </Column>
      </Row>
      <Row>
        <Column flexGrow={1}>
          <Button.Kickstart
            finished={!!campaignActions}
            onClick={onCreateAction}
            kickstartIcon={
              (!!campaignActions && <FiCheck size={'1.2rem'} color={'#fff'} />) || (
                <VVDArrow style={{ width: '18px', height: '16px' }} />
              )
            }
            title={'Plan je eerste canvasactie in'}
          >
            <>
              <FiChevronRight size={'1.3rem'} />
              <span>Start je eerste actie</span>
            </>
          </Button.Kickstart>
        </Column>
      </Row>
      {isCloseable && (
        <div className={styles.closeable}>
          <div>
            <Text.Large color={'white'} classes={styles.header}>
              Je campagne is klaar voor de start!
            </Text.Large>
            <Button.Primary onClick={onCloseKickStarter} classes={styles.button}>
              Sluit de kickstarter
            </Button.Primary>
          </div>
        </div>
      )}
    </Card>
  )
}
