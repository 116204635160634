.dayOfWeek {
  text-transform: capitalize;
  font-weight: 350;
  font-size: var(--size-default);
}
.date {
  font-weight: 500;
  font-size: var(--size-default);
  text-transform: lowercase;
}
.time {
  font-size: var(--size-default);
  font-weight: lighter;
  color: var(--colors-darkgray);
  margin-left: 0.2rem;
}
.icon {
  justify-content: right;
  flex-grow: 1;
  display: flex;
}
.expired .dayOfWeek,
.expired .date,
.expired .time {
  font-size: var(--size-medium);
}
.large {
  justify-content: left;
}
.large .dayOfWeek,
.large .date,
.large .time {
  font-size: var(--size-default);
}
.large .icon {
  flex-grow: 0;
  margin-left: 0.5rem;
}
