import { newsReducer, errorReducer, listAllNewsItems, getNextNewsPage, getNewsItemById } from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const news = fromReducer(combineReducers({ newsItems: newsReducer }, { error: errorReducer }))

export const withNews = withReducerState(
  (state) => {
    return { ...state?.newsItems }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      getAllNewsItems: (per_page, page) => dispatch(listAllNewsItems(per_page, page)),
      getNextNewsPage: () => dispatch(getNextNewsPage()),
      getNewsItem: (id) => dispatch(getNewsItemById({ id }))
    }
  }
)(news)

export const withNewsErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(news)
