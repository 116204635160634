.tabcontainer {
  margin-top: 20px;
  align-items: baseline;
  position: relative;
}

.tabcontainer::after {
  content: '';
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  width: auto;
  z-index: -2;
  border-bottom: 2px solid var(--colors-lightgray);
}

.tab {
  position: relative;
  text-decoration: none !important;
}
.tab.selected::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: auto;
  border-bottom: 2px solid var(--colors-orange);
}
.tab.selected.animate::before {
  width: auto;
  animation: slide 0.3s linear;
}
@keyframes slide {
  0% {
    left: 50%;
    width: 0%;
  }
  100% {
    left: 0;
    width: 100%;
    right: 0;
  }
}

.tabContent {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
