import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { Card, Text, LinkButton } from '../../../core'
import styles from './contact-coordinator.module.css'

export const ContactCoordinatorCard = ({ classes, contacts }) => {
	return (
		<Card classes={`${classes ? classes : ''}`}>
			<Text.Large classes={`semibold margin-bottom-24`} color={'black'}>
				Stel je vraag direct aan een coördinator
			</Text.Large>
			{contacts && (
				<Row wrap className={'gapL'}>
					{contacts.map((contact, idx) => {
						return (
							<Column key={idx}>
								<Text.Medium classes={`semibold`}>{contact.description}</Text.Medium>
								<Text.Medium>
									<LinkButton.External
										url={`mailto:${contact.email}`}
										classes={`${styles.link}`}
										color={'blue'}
									>
										{contact.email}
									</LinkButton.External>
								</Text.Medium>
								<Text.Medium>{contact.phone}</Text.Medium>
							</Column>
						)
					})}
				</Row>
			)}
		</Card>
	)
}
