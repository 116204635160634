import React from 'react'
import styles from './display.module.css'

const Text = ({ color, classNames, children }) => {
  return (
    <div className={`${styles.text} ${classNames ? classNames : ''} ${color ? styles[color] : ''}`}>
      {children}
    </div>
  )
}

Text.XXL = ({ ...props }, ref) => (
  <Text classNames={`${styles.xxl} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)
Text.XL = ({ ...props }, ref) => (
  <Text classNames={`${styles.xl} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)
Text.Large = ({ ...props }) => (
  <Text classNames={`${styles.large} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)
Text.SemiLarge = ({ ...props }) => (
  <Text classNames={`${styles.semilarge} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)

Text.Medium = ({ ...props }) => (
  <Text classNames={`${styles.medium} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)
Text.Small = ({ ...props }) => (
  <Text classNames={`${styles.small} ${props.classes}`} {...props}>
    {props.children}
  </Text>
)

export { Text }
