.header {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
}

.avatar {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.avatar.hide {
  opacity: 0;
}

.nav {
  display: flex;
  align-items: center;
  text-decoration-color: var(--colors-darkgray) !important;
}

.navItem {
  color: var(--colors-darkgray);
  margin-left: 8px;
}

.nav path {
  fill: var(--colors-darkgray);
}

.breadcrumbs {
  display: flex;
  align-items: center;
  text-decoration-color: var(--colors-blue) !important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: var(--colors-blue);
  text-transform: capitalize;
}

.breadcrumb.inactive {
  font-size: var(--size-medium);
  color: var(--colors-darkgray);
}

.avatar_wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.button {
  position: relative;
  background-color: var(--colors-orange);
  border: none;
  justify-content: center;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  flex-grow: 1;
  cursor: pointer;
  font-size: var(--size-small);
  font-weight: 200;
  display: flex;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 0px 0px var(--colors-blue);
  box-shadow: 3px 3px 0px 0px var(--colors-blue);
  border-radius: 1rem;
  transition: all 0.2s ease;
}

.button:active,
.button:hover {
  background-color: var(--color-button-primary-hover);
}

.button:active,
.chevron:active {
  transform: translate(1px, 1px);
}

.button.cta {
  background-color: var(--color-button-cta);
  color: var(--colors-blue);
}

.button.cta:hover {
  background-color: var(--color-button-cta-secondary);
}

.button.disabled {
  pointer-events: none;
  background-color: lightgrey;
  color: darkgrey;
  user-select: none;
}

.chevron {
  margin: 1px 0px 0px 5px;
  color: var(--colors-blue);
  transition: all 0.2s linear;
}

.chevron.open {
  transform: scale(1, -1);
}

.dropdownMenu {
  background-color: white;
  position: absolute;
  top: 41px;
  display: flex;
  width: 150px;
  transform: translate(-47%);
  border: var(--border);
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
  z-index: 1200;
}

.dropdownMenuUnorderedList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.dropdownItem {
  list-style: none;
  /* list-style: none; */
  /* list-style-type: none; */
  align-items: center;
  display: flex;
  transform: background 1s;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500 ease;
}

.menu-primary-exit {
  position: absolute;
}

.currentCampaign {
  height: 32px;
  background: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 14px;
}