export const downloadsConstants = {
  DOWNLOADS_START: 'DOWNLOADS_START',
  DOWNLOADS_END: 'DOWNLOADS_END',

  DOWNLOADS_BUSY: 'DOWNLOADS_BUSY',

  DOWNLOADS_LIST_SUCCESS: 'DOWNLOADS_LIST_SUCCESS',
  DOWNLOADS_LIST_FAILURE: 'DOWNLOADS_LIST_FAILURE',

  DOWNLOADS_ITEM_GET_SUCCESS: 'DOWNLOADS_ITEM_GET_SUCCESS',
  DOWNLOADS_ITEM_GET_FAILURE: 'DOWNLOADS_ITEM_GET_FAILURE',

  FAILURE: 'FAILURE'
}
