import React from 'react'
import { VVDModal } from '../vvd.modal'
import styles from './info.modal.module.css'
import { FiX } from 'react-icons/fi'

export const InfoModal = ({ cancel, confirm, title, onClose, children, content, ...rest }) => {
  return (
    <VVDModal
      closeOnClickOutside={true}
      title={title}
      closeIcon={<FiX size={'1.5rem'} color={'black'} />}
      modalClass={styles.modalInfo}
      titleClass={styles.title}
      content={content}
      {...(rest && {
        ...rest,
        onClose
      })}
    >
      {children}
    </VVDModal>
  )
}
