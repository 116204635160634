.card {
  border: 2px solid var(--colors-lightgray);
  padding: 12px;
  min-width: auto;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  transform: scale(1);
  animation: fadeIn 0.8s linear;
}
.card.stretch {
  flex-wrap: wrap;
}
.card.fixed {
  flex-grow: 1;
  max-width: 9rem;
  min-width: 9rem;
  min-height: 6rem;
  max-height: 6rem;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: center;
  justify-content: end;
}

@keyframes fadeIn {
  0% {
    transform: scale(0.5);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  75% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
