import React from 'react'
import styles from './form.module.css'

const Select = React.forwardRef(
  ({ name, placeholder, label, startAdornment, endAdornment, classes, children, ...rest }, ref) => {
    return (
      <div className={`${styles.select} ${classes || ''}`}>
        <select ref={ref} autoComplete='off' name={name} placeholder={placeholder} {...rest}>
          {children}
        </select>
        {endAdornment && <div className={`${styles.adornment} ${styles.end}`}>{endAdornment}</div>}
      </div>
    )
  }
)
Select.displayName = 'Select'
export { Select }
