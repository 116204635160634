import React from 'react'
import styles from './tabs.module.css'

export const TabContainer = ({ tabs, children, style }) => {
  return (
    <div className={`${styles.tabcontainer} flex hgrow-1 gap`} style={style}>
      {tabs}
      <div className={styles.tabContent}>{children}</div>
    </div>
  )
}
