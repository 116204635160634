import React from 'react'
import { emojis } from '../../constants'
import { Card, Text } from '../../core'
import styles from './action/action-result-item.module.css'
import { Emoji } from '../emoji'

export const NoDataCard = ({ text, emoji = emojis.SLEEPING, classes }) => {
  return (
    <Card classes={`${styles.details} row flexcenter gapS ${classes ? classes : ''} `}>
      <Text.Medium classes={'light gray'}>{text || `Geen gegevens om te tonen`}</Text.Medium>
      <span style={{ lineHeight: '20px' }} className={'textSemiLarge semibold'} role='img'>
        <Emoji.Medium hexCode={emoji} />
      </span>
    </Card>
  )
}
