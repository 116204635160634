import React from 'react'
import { errorReducer, loadMapData, mapReducer } from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const map = fromReducer(combineReducers({ map: mapReducer }, { error: errorReducer }))

export const withMap = withReducerState(
  (state) => {
    return { ...state?.map, loadingMapData: state?.map?.loading }
  },
  (dispatch) => {
    return {
      getMapData: (...args) => dispatch(loadMapData(...args))
    }
  }
)(map)

export const withMapErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(map)
