import React, { useEffect, useState } from 'react'
import { LoadingIndicator, MapCard } from '../card'
import { withMap, withMapErrors } from '../hoc'
import { ErrorDisplay, Text } from '../../core'
import { initIDB, setIDBData, getIDBData, IDBReady } from '../../utils/use-db'

const MapErrors = withMapErrors(({ errors, errorMessage }) => {
  return <ErrorDisplay errors={errors?.map || errorMessage} />
})

const CanvasMapComponent = ({ getMapData, loadingMapData, mapData = null }) => {
  const [locationData, setLocationData] = useState(null)

  const handleRefresh = () => {
    getMapData()
  }

  const getData = async () => {
    initIDB()
    const ready = await IDBReady()

    if (ready) {
      await getIDBData()
        .then((storedData) => {
          if (typeof storedData !== 'undefined' && storedData !== null && storedData) {
            setLocationData({ ...storedData, reverse: false })
          }
        })
        .catch((err) => {
          getMapData()
        })
    } else {
      getMapData()
    }
  }

  const storeData = async () => {
    if (mapData) {
      setLocationData({ data: mapData, time: new Date().getTime(), reverse: true })

      const ready = await IDBReady()
      if (ready) {
        setIDBData(mapData)
      }
    }
  }

  useEffect(() => {
    getData()
  }, [getMapData, setLocationData])

  useEffect(() => {
    storeData()
  }, [mapData])

  return (
    <>
      <MapErrors />
      <LoadingIndicator loading={loadingMapData} title={'Loading canvaskaart'}>
        <>
          {locationData?.data && (
            <MapCard
              key={locationData?.time}
              title={
                <Text.SemiLarge classes={`semibold`} color={'black'}>
                  Canvaskaart
                </Text.SemiLarge>
              }
              locationData={locationData}
              onRefresh={handleRefresh}
            />
          )}
        </>
      </LoadingIndicator>
    </>
  )
}

export const CanvasMap = withMap(CanvasMapComponent)
