import { votingGoalService } from '../../services'
import { votingGoalConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })
const requestGetVoting = action(votingGoalConstants.VOTINGGOAL_REQUEST)
const successGetVoting = action(votingGoalConstants.VOTINGGOAL_SUCCESS)
const failureGetVoting = action(votingGoalConstants.VOTINGGOAL_FAILURE)

const requestSaveVoting = action(votingGoalConstants.VOTINGGOAL_SAVE_REQUEST)
const successSaveVoting = action(votingGoalConstants.VOTINGGOAL_SAVE_SUCCESS)
const failureSaveVoting = action(votingGoalConstants.VOTINGGOAL_SAVE_FAILURE)

const getVotingGoal = () => (dispatch, getState) => {
  dispatch(requestGetVoting())
  votingGoalService
    .getVotingGoal()
    .then((response) => {
      dispatch(successGetVoting(response.data))
    })
    .catch((err) => dispatch(failureGetVoting(err)))
}

const saveVotingGoal = (data) => (dispatch, getState) => {
  dispatch(requestSaveVoting())
  votingGoalService
    .saveVotingGoal({ ...data })
    .then((response) => {
      dispatch(successSaveVoting(response))
    })
    .catch((err) => dispatch(failureSaveVoting(err)))
}

export { saveVotingGoal, getVotingGoal }
