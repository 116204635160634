.modalInfo {
  border-radius: 0;
  min-width: auto;
  max-height: auto;
  width: 15rem;
  padding-bottom: 24px;
}
.title {
  color: var(--colors-black);
  font-size: var(--size-large);
}
