.navigation {
  margin-top: 1px;
  margin: 1px 0 12px 30px;
  height: 100vh;
  width: 10rem;
  overflow: visible;
  margin-bottom: 100vh;
}

.logoTitleWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  cursor: pointer;
}
.logoTitleWrapper svg {
  margin-left: -12px;
}

.logo {
  color: var(--colors-blue);
  margin-bottom: 40px;
  font-size: larger;
}
.title_placeholder {
  color: var(--colors-blue);
  font-weight: 600;
  word-break: break-word;
}
.navItem {
  color: var(--colors-blue);
  cursor: pointer;
  font-size: var(--size-medium);
}

.light {
  font-weight: 400;
}
.selected {
  font-weight: 600;
  margin-left: 8px;
}

.navigationItemWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 28px;
  white-space: nowrap;
}

.vvdArrow {
}
