import React from 'react'
import styles from './news.module.css'
import { Card, CardBody, Text } from '../../../core'
import rutte from '../../../assets/images/rutte.jpeg'
import moment from 'moment'
import 'moment/locale/nl'

export const NewsCard = ({ newsItem = {}, loading, ...rest }) => {
  const formattedDate = moment(newsItem.created_at).format('DD MMMM YYYY')
  return (
    <Card classes={`${styles[`news-card`]} ${styles.interactive} ${loading ? styles.loading : ''}`} clickable {...rest}>
      <div
        style={{
          backgroundImage: loading ? '' : `url(${newsItem?.featured_image?.responsive?.md || rutte})`,
          backgroundSize: 'cover',
          width: '100%',
          height: '7.5rem',
          backgroundRepeat: 'no-repeat'
        }}
      ></div>
      <CardBody classes={styles.body}>
        {!loading && <><Text.Small classes={styles.date} color={`darkgray`}>
          {formattedDate}
        </Text.Small>
          <Text.Large classes={`bold ${styles.title}`} color={'blue'}>
            {newsItem.title}
          </Text.Large>
          <Text.Medium classNames={`textDefault ${styles.text}`}>{newsItem.preview}</Text.Medium>
        </>}
      </CardBody>
    </Card>
  )
}
