import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const teamResource = createResource({
  endpoint: `${API_URL}/hub/users`,
  auth: () => userStorage[USER_TOKEN].get(),
  options: { mode: 'cors' }
})

/**
 *
 * Service for the users/volunteers/team api.
 */
export const teamService = {
  listUsers: () => teamResource.get().json(),
  getUser: (userId) => teamResource.url(userId).get().json(),
  deleteUser: (userId) => teamResource.url(userId).delete().res(),
  saveUser: (userData) => teamResource.post(userData).json(),
  updateUser: (userId, userData) => teamResource.url(userId).post(userData).json(),
  inviteUser: (userId) => teamResource.url(userId).url('/invite').post().res()
}
