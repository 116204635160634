.card {
  font-size: 1rem;
}

.link {
  font-weight: normal;
}

img.qrcode {
  width: 8rem;
  height: 8rem;
}
