import React, { useMemo } from 'react'
import styles from './meatballs.stack.module.css'

const getSlice = (array, start, end, arr) => {
  const part = array.slice(start, end)
  if (start < array.length && arr.length < part.length) {
    arr.push(Array.from(part))
    const max = Math.max(...part)
    return getSlice(array, max + 1, end + part.length + 1, arr)
  } else {
    return arr
  }
}
export const MeatballStack = ({ total, filled }) => {
  const totalBallGroups = useMemo(() => {
    return getSlice(Array.from(Array(total).keys()), 0, 1, [])
  }, [total])

  const maxToFill = useMemo(() => {
    return (
      totalBallGroups.reduce((prev, curr) => {
        return prev + curr.length
      }, 0) - filled
    )
  }, [totalBallGroups, filled])

  return (
    <div className={styles.container}>
      {totalBallGroups.map((grpList, grpLstIdx) => (
        <div key={grpLstIdx} className={styles.meatballGroup}>
          {grpList.map((grp, idx) => (
            <span
              key={`meatball-${idx}`}
              className={`${styles.meatball} ${grp >= maxToFill ? styles.fill : ''}`}
            ></span>
          ))}
        </div>
      ))}
    </div>
  )
}
