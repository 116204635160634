.card {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 0;
  min-width: 300px;
  padding: 20px 25px;
  padding: 24px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 0px 1px transparent;
}
.flat {
  background-color: transparent;
}
.card.interactive {
  cursor: pointer;
  transition: background-color 0.6s ease, box-shadow 0.2s ease-in;
}

.card.interactive:hover {
  background-color: var(--colors-card-hover);
  box-shadow: 2px 2px 0px 1px var(--colors-midgray);
}
.card.interactive:active {
  transform: translate(1px, 1px);
}

.card .cardHeader {
  display: flex;
  position: relative;
  color: var(--colors-blue);
  margin-top: 0;
  margin-bottom: 24px;
  flex-grow: 0;
  align-items: center;
}

.cardHeader .cardAdornment {
  position: absolute;
  left: -27px;
}
.cardHeader .cardAdornment svg {
  transform: scale(2.5);
}

.card .cardTitle {
  color: var(--colors-black);
  font-size: var(--size-medium);
  max-width: 18rem;
  font-weight: light;
  flex-grow: 0;
}

.card .cardBody {
  margin-top: 12px;
  color: var(--colors-darkgray);
  font-size: var(--size-medium);
  font-weight: normal;
  flex-grow: 0;
}

.card.border {
  border: 2px solid var(--colors-lightgray);
  padding: 12px;
  min-width: auto;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.card.stretch {
  flex-wrap: wrap;
}
.card.fixed {
  flex-grow: 1;
  max-height: 6rem;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  justify-content: flex-start;
}

.card.stats .cardTitle {
  font-size: var(--size-xlarge);
  display: flex;
  flex-grow: 1;
}
.card.stats .cardBody {
  font-size: var(--size-medium);
  display: flex;
  flex-grow: 1;
}
