import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Custom hook for using a delay (debouncing) on a state change.
 * @param {*} value The value which needs to be 'debounced'.
 * @param {*} delay The amount of ms.
 */
export const useDownloadBlob = (blob) => {
	const url = useMemo(
		() => {
			if (!blob) return null
			return URL.createObjectURL(blob)
		},
		[ blob ]
	)

	const download = useMemo(
		() => {
			if (!url) return null
			return (filename = 'download') => {
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				const clickHandler = () => {
					setTimeout(() => {
						URL.revokeObjectURL(url)
						a.removeEventListener('click', clickHandler)
						a = null
					}, 150)
				}
				a.addEventListener('click', clickHandler, false)
				a.click()
			}
		},
		[ url ]
	)
	return download
}
