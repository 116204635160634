.cardcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 24px;
}

.text {
  font-size: 1rem;
}

.link {
  font-weight: normal;
}
