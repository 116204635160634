import React, { useMemo } from 'react'
import { Text, Card } from '../../../core'
import { Column, Row } from 'simple-flexbox'
import styles from './campaign-stats.module.css'
import { Emoji } from '../../emoji'
import VVDLogo from '../../../assets/images/vvd-logo.svg'
import { getVibeIcon } from '../../../utils'

export const CampaignStatsCard = ({ data }) => {
  const vibeIcon = useMemo(() => {
    return getVibeIcon(data?.vibe)
  }, [data])

  return (
    <Card
      style={{ justifyContent: 'normal' }}
      classes={`flexcenter tpad bpad lpad rpad nopadding gapS hgrow-0`}
    >
      <Text.SemiLarge classes={`semibold`} color={'black'}>
        Campagne data
      </Text.SemiLarge>

      <Row wrap className={'gapS'} justifyContent='space-between'>
        <Column flexGrow={'0'} className={'gapS'} style={{ width: '40%' }}>
          <Card classes={`${styles.card} ${styles.stretch}`}>
            <Column alignItems='center' justifyContent='space-between' className={'gapS tpad'}>
              <Text.XL color={'black'}>{data?.conversations || 0}</Text.XL>
              <Text.Medium color={'darkgray'}>Gesprekken gevoerd</Text.Medium>
            </Column>
            <Column justifyContent='start' className={'bpad'}>
              <Row className={'gapXs'} alignItems='center'>
                <Text.Medium classes={'bold hgrow-0'} color={'black'}>
                  {data?.absent || 0}
                </Text.Medium>
                <Text.Medium color={'darkgray'}>Niet thuis</Text.Medium>
              </Row>
              <Row className={'gapXs'} alignItems='center'>
                <Text.Medium classes={'bold hgrow-0'} color={'black'}>
                  {data?.uninterested || 0}
                </Text.Medium>
                <Text.Medium color={'darkgray'}>Niet geïnteresseerd</Text.Medium>
              </Row>
            </Column>
          </Card>
        </Column>

        <Column flexGrow={'0'} className={'gapL'} alignItems='center'>
          <Card classes={`${styles.card} ${styles.fixed}`}>
            <Column alignItems='center' justifyContent='space-between' className={'gapS'}>
              <Text.XL color={'black'}>{data?.emails || 0}</Text.XL>
              <Text.Medium color={'darkgray'}>E-mail adressen verzameld</Text.Medium>
            </Column>
          </Card>
          <Card classes={`${styles.card} ${styles.fixed}`}>
            <Column alignItems='center' justifyContent='space-between' className={'gapS'}>
              <Row style={{ marginBottom: '12px' }}>
                <Emoji.XLarge hexCode={vibeIcon} />
              </Row>
              <Text.Medium classes={'vend'} color={'darkgray'}>
                Gemiddelde ervaring
              </Text.Medium>
            </Column>
          </Card>
        </Column>

        <Column flexGrow={'0'} className={'gapL'} alignItems='center'>
          <Card classes={`${styles.card} ${styles.fixed}`}>
            <Column alignItems='center' justifyContent='space-between' className={'gapS'}>
              <Text.XL color={'black'}>{data?.voting || 0}</Text.XL>
              <Text.Medium color={'darkgray'}>Mensen gaan stemmen</Text.Medium>
            </Column>
          </Card>
          <Card classes={`${styles.card} ${styles.fixed}`}>
            <Column alignItems='center' justifyContent='space-between' className={'gapS'}>
              <Text.XL classes={'hcenter'} color={'black'}>
                {data?.voting_for_vvd || 0}
                <VVDLogo />
              </Text.XL>
              <Text.Medium color={'darkgray'}>Zeer grote kans</Text.Medium>
            </Column>
          </Card>
        </Column>
      </Row>
    </Card>
  )
}
