import React from 'react'
import styles from './card.module.css'

export const CardHeader = ({ adornment, classes, children, ...rest }) => {
  return (
    <h2 {...rest} className={`${styles.cardHeader} ${classes ? classes : ''}`}>
      {adornment && <div className={styles.cardAdornment}>{adornment}</div>}
      {children}
    </h2>
  )
}
