import {
  USER_NAME,
  USER_TOKEN,
  USER_TOKEN_EXPIRES_IN,
  USER_SUBTITLE,
  KICKSTARTER_CLOSED,
  KICKSTARTER_CLOSED_BY_USER,
  USER_ROLE
} from '../constants'
import { makeStorage } from '.'

export const userStorage = makeStorage(
  USER_TOKEN,
  USER_NAME,
  USER_TOKEN_EXPIRES_IN,
  USER_SUBTITLE,
  USER_ROLE
)
export const uiStorage = makeStorage(KICKSTARTER_CLOSED, KICKSTARTER_CLOSED_BY_USER)
