export const defaultBackground = 'default'

export const BG_COLORS = {
  GRAY: 'lightgray',
  BLUE: 'blue',
  ORANGE: 'orange',
  DEFAULT: defaultBackground
}
export const backgrounds = {
  login: BG_COLORS.BLUE,
  'create-password': BG_COLORS.ORANGE,
  forgotpassword: BG_COLORS.BLUE
}
export const KICKSTARTER_CLOSED = 'KICKSTARTER_CLOSED'
export const KICKSTARTER_CLOSED_BY_USER = 'KICKSTARTER_CLOSED_BY_USER'
