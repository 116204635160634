export const teamConstants = {
  TEAM_START: 'TEAM_START',
  TEAM_END: 'TEAM_END',

  TEAM_BUSY: 'TEAM_BUSY',

  TEAM_LIST_SUCCESS: 'TEAM_LIST_SUCCESS',
  TEAM_LIST_FAILURE: 'TEAM_LIST_FAILURE',

  USER_GET_SUCCESS: 'USER_GET_SUCCESS',
  USER_GET_FAILURE: 'USER_GET_FAILURE',

  USER_DELETE_START: 'USER_DELETE_START',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',

  USER_SAVE_SUCCESS: 'USER_SAVE_SUCCESS',
  USER_SAVE_FAILURE: 'USER_SAVE_FAILURE',

  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  USER_INVITE_SUCCESS: 'USER_INVITE_SUCCESS',
  USER_INVITE_REQUEST: 'USER_INVITE_REQUEST',
  USER_INVITE_FAILURE: 'USER_INVITE_FAILURE',

  FAILURE: 'FAILURE'
}
