import { useCallback, useMemo } from 'react'
import { useNavigation } from './use-navigation'

const paramsToString = (params) => {
  let _paramsToString = '?'
  const fromEntries = Object.entries(params)
  const size = fromEntries.length - 1
  let idx = 0
  for (const [key, value] of fromEntries) {
    _paramsToString += `${key}=${value}${idx < size ? '&' : ''}`
    idx++
  }
  return _paramsToString
}

export const useHistory = () => {
  const { fromQueryString, currentLocation, searchParams, navigate } = useNavigation()

  const fullLocation = useMemo(
    () => `${currentLocation}${searchParams.toString() ? `?${searchParams}` : ''}`,
    [currentLocation, searchParams]
  )

  const pushHistory = useCallback(
    (params) => {
      navigate(`${currentLocation}${paramsToString(params)}`)
    },
    [currentLocation, navigate]
  )

  const appendHistory = useCallback(
    (params) => {
      navigate(`${fullLocation}${paramsToString(params)}`)
    },
    [fullLocation, navigate]
  )

  const replaceHistory = useCallback(
    (param, paramValue) => {
      searchParams.set(param, paramValue)
      navigate(`${currentLocation}?${searchParams.toString()}`)
    },
    [searchParams, currentLocation, navigate]
  )

  return {
    appendHistory,
    pushHistory,
    fromQueryString,
    replaceHistory
  }
}
