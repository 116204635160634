import { useCallback, useEffect, useState } from 'react'

const createFetchHook = (key, fn) => {
  // The hook:
  return ({ onSuccess, onError, onLoadStatus } = {}, dependencies = []) => {
    const [loading, setLoading] = useState()
    const [response, setResponse] = useState()
    const [error, setError] = useState()

    const execute = useCallback(
      async (...args) => {
        onLoadStatus ? onLoadStatus(true) : setLoading(true)
        fn(...args)
          .then((response) => {
            if (onSuccess) {
              setResponse(onSuccess(response))
            } else {
              setResponse(response)
            }
          })
          .catch((err) => {
            onError ? onError(err) : setError(err)
          })
          .finally(() => {
            onLoadStatus ? onLoadStatus(false) : setLoading(false)
          })
      },
      [setLoading, setResponse, setError, onError, onSuccess, onLoadStatus]
    )

    useEffect(() => {
      if (dependencies && dependencies.length) {
        execute(...dependencies)
      }
    }, [...dependencies])

    return [execute, loading, response, error]
  }
}

export const createUseFetch = (resource) => {
  if (!resource) return null
  return Array.from(Object.entries(resource)).reduce((memo, [key, value]) => {
    memo[key] = createFetchHook(key, value)
    return memo
  }, {})
}
