import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const downloadsResource = createResource({
	endpoint: `${API_URL}/hub/downloads`,
	auth: () => userStorage[USER_TOKEN].get(),
	options: { mode: 'cors' }
})

/**
 *
 * Service for the news api.
 */
export const downloadsService = {
	listDownloadsItems: () => downloadsResource.get().json(),
	getDownloadsItem: (downloadItemId) => downloadsResource.url(downloadItemId).url('/download').get().blob()
}
