.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  gap: 12px;
  background-color: #fff;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  z-index: 1001;
  transform: translate(-50%, -50%);
  outline: none;
  padding: 1.5rem;
  padding-bottom: 12px;
  min-height: 20vh;
  min-width: 30vw;
  max-width: 40vw;
}

.modal .sticky {
  position: sticky;
  top: 0;
}

.modal .closeIcon {
  display: flex;
  justify-content: end;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal .title {
  font-size: var(--size-large);
  font-weight: 500;
  color: var(--colors-blue);
  display: flex;
  align-items: center;
}

.modal .content {
  display: flex;
  align-items: flex-start;
  color: var(--colors-black);
  font-size: var(--size-medium);
}
.modal .footer {
  bottom: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: 12px;
  justify-content: flex-end;
}
.modal .footer * {
  margin: 0.2rem;
}
.disabled * {
  opacity: 0.45;
}
.disabled * {
  pointer-events: none;
  user-select: none;
}
