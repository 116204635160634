import { nanoid } from 'nanoid'

import buurten_volunteer from 'url:../assets/toolbox/links/Even Buurten handleiding vrijwilligers.pdf'
import campagnehub_handleiding from 'url:../assets/toolbox/links/Handleiding CampagneHub.pdf'
import your_team from 'url:../assets/toolbox/links/Jouw team & jouw campagne.pdf'
import datakaarten from 'url:../assets/toolbox/links/Gesprekken aan de deur & datakaarten.pdf'
import datakaart_doelgroep from 'url:../assets/toolbox/links/Doelgroepen Datakaart.pdf'
import roadshows from 'url:../assets/toolbox/links/FAQ Roadshows.pdf'
import fotorichtlijn from 'url:../assets/toolbox/links/Fotorichtlijn GR2022.pdf'
import stemwijzerhulp from 'url:../assets/toolbox/links/Stemwijzerhulp GR2022.pdf'
import veilig_langs_de_deuren from 'url:../assets/toolbox/links/Veilig-langs-de-deuren-dec2021.pdf'

import kerst from 'url:../assets/toolbox/downloads/content/kerst.zip'
import kies from 'url:../assets/toolbox/downloads/content/kies.zip'
import mantelzorg from 'url:../assets/toolbox/downloads/content/mantelzorg.zip'
import ondernemer from 'url:../assets/toolbox/downloads/content/ondernemer.zip'
import oudniew from 'url:../assets/toolbox/downloads/content/oudnieuw.zip'
import sinterklaas from 'url:../assets/toolbox/downloads/content/sinterklaas.zip'
import valentijn from 'url:../assets/toolbox/downloads/content/valentijn.zip'
import dagvdondernemer from 'url:../assets/toolbox/downloads/content/DagvandeOndernemer.pdf'

import thumb_kerst from '../assets/toolbox/downloads/thumbs/kerst.png'
import thumb_kies from '../assets/toolbox/downloads/thumbs/kies.png'
import thumb_mantelzorg from '../assets/toolbox/downloads/thumbs/mantelzorg.png'
import thumb_ondernemer from '../assets/toolbox/downloads/thumbs/ondernemer.png'
import thumb_oudnieuw from '../assets/toolbox/downloads/thumbs/oudnieuw.png'
import thumb_sinterklaas from '../assets/toolbox/downloads/thumbs/sinterklaas.png'
import thumb_valentijn from '../assets/toolbox/downloads/thumbs/valentijn.png'
import thumb_dagvdondernemer from '../assets/toolbox/downloads/thumbs/dagvdondernemer.png'

export const content = [
  {
    id: nanoid(),
    image: thumb_dagvdondernemer,
    name: 'Flyer: dag van de ondernemer',
    file: dagvdondernemer,
    info: 'pdf file 462KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_kerst,
    name: 'Kerst',
    file: kerst,
    info: 'zip file 1.1MB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_kies,
    name: 'Kies VVD',
    file: kies,
    info: 'zip file 122KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_mantelzorg,
    name: 'Dag van de mantelzorg',
    file: mantelzorg,
    info: 'zip file 272KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_ondernemer,
    name: 'Social media: dag van de ondernemer',
    file: ondernemer,
    info: 'zip file 205KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_oudnieuw,
    name: 'Oud & nieuw',
    file: oudniew,
    info: 'zip file 455KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_sinterklaas,
    name: 'Sinterklaas',
    file: sinterklaas,
    info: 'zip file 681KB',
    download: true
  },
  {
    id: nanoid(),
    image: thumb_valentijn,
    name: 'Valentijnsdag',
    file: valentijn,
    info: 'zip file 230KB',
    download: true
  }
]

export const links = [
  {
    id: nanoid(),
    name: 'Veilig langs de deuren',
    file: veilig_langs_de_deuren,
    download: true
  },
  {
    id: nanoid(),
    name: 'Fotorichtlijn',
    file: fotorichtlijn,
    download: true
  },
  {
    id: nanoid(),
    name: 'Stemwijzerhulp',
    file: stemwijzerhulp,
    download: true
  },
  {
    id: nanoid(),
    name: 'Doelgroepen datakaart',
    file: datakaart_doelgroep,
    download: true
  },
  {
    id: nanoid(),
    name: 'Roadshows FAQ',
    file: roadshows,
    download: true
  },
  {
    id: nanoid(),
    name: 'Brandbook ww: vvd',
    file: 'https://vvd.brandbook.io/vvd/',
    download: false
  },
  {
    id: nanoid(),
    name: 'QR-code generator ww: markrutte2021',
    file: 'https://qr.vvd.nl/',
    download: false
  },

  {
    id: nanoid(),
    name: 'Even buurten handleiding vrijwilligers',
    file: buurten_volunteer,
    download: true
  },
  {
    id: nanoid(),
    name: 'Handleiding CampagneHub',
    file: campagnehub_handleiding,
    download: true
  },
  {
    id: nanoid(),
    name: 'Jouw team, jouw campagne',
    file: your_team,
    download: true
  },
  {
    id: nanoid(),
    name: 'Gesprekken en datakaarten',
    file: datakaarten,
    download: true
  }
]
