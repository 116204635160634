.details {
  min-height: 4rem;
  max-height: 4rem;
  max-width: 4rem;
  flex-grow: 0;
  padding: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--colors-lightgray);
}

.details .header,
.details .body {
  margin: 0;
}
.details .header {
  font-size: var(--size-large);
  font-weight: 500;
  display: flex;
  gap: 0.5rem;
}
.details .header.light {
  color: var(--colors-midgray);
}

.details.expired .header {
  color: var(--colors-black);
}

.details .body {
  font-size: var(--size-medium);
  font-weight: 350;
}
.details .body * {
  flex-grow: 0;
}

.details .hr {
  margin: 0;
  border-bottom: 2px solid var(--colors-lightgray);
}
