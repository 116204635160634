.header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assetLink {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid var(--colors-lightgray);
  margin-bottom: 8px;
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
  color: var(--colors-midgray);
}
.disabled * {
  fill: var(--colors-midgray);
}
