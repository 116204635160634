import * as yup from 'yup'

export const LOGIN = yup.object().shape({
  email: yup.string().email('Dit lijkt geen geldig e-mailadres'),
  password: yup.string().min(5).required('')
})

export const FORGOT_PASSWORD = yup.object().shape({
  email: yup.string().email().required()
})

export const REGISTER = yup.object().shape({
  password: yup.string().min(5).required(''),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Wachtwoorden zijn niet hetzelfde')
})

export const CANVAS_ACTIE = yup.object().shape({
  name: yup.string().required('Een titel is verplicht'),
  datetime: yup.string().nullable(true).required('Vul een startdatum in'),
  // questionnaire_id: yup.string().required('Vragenlijst is verplicht'),
  address: yup.string().required('Locatie is verplicht'),
  introduction: yup
    .string()
    .required('Een bericht aan vrijwilligers is verplicht')
    .max(140, 'Het bericht mag niet langer zijn dan 140 karakters')
})

export const NEW_VOLUNTEER = yup.object().shape({
  name: yup.string().required('Dit veld mag niet leeg zijn'),
  email: yup
    .string()
    .email('Vul een geldig e-mailadres in')
    .required('Vul een geldig e-mailadres in')
})

export const VOTING_GOAL = yup.object().shape({
  electorate_base_year: yup.string().required('Stemgerechtigden mag niet leeg zijn.'),
  electorate_target_year: yup.string().required('Stemgerechtigden mag niet leeg zijn.'),
  votes_base_year: yup.string().required('Aantal uitgebrachte stemmen mag niet leeg zijn.'),
  total_amount_of_seats: yup.string().required('Aantal zetels mag niet leeg zijn.'),
  //
  candidates: yup.string().required('Aantal kandidaten mag niet leeg zijn.'),
  votes_base_year_for_vvd: yup.string().required('Stemmen op de VVD mag niet leeg zijn.'),
  vvd_members: yup.string().required('VVD leden mag niet leeg zijn.'),
  seats_target_year: yup.string().required('Aantal beoogde zetels mag niet leeg zijn.')
})
