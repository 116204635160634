import wretch from 'wretch'

const createApi = (resource) => {
  const addUrlPart = (url, params) => {
    let partedUrl = url ? `/${url}?` : '?'
    if (params) {
      params = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
      partedUrl += new URLSearchParams(params)
    }
    return partedUrl
  }

  const get = (url, params) => resource.url(addUrlPart(url, params)).get()
  const post = (json) => resource.post(json)
  const put = (json) => resource.put(json)
  const del = (url) => resource.url(addUrlPart(url)).delete()
  const url = (url) => resource.url(addUrlPart(url))

  return { get, post, put, del, url }
}

/**
 * Creates a 'resource' with the given endpoint.
 * @param {*} endpoint The API's endpoint.
 * @param options The API's options.
 * @param auth The API's auth token.
 * @param headers The API's headers.
 * @returns Crud functions to be used in a service.
 */
export const createResource = ({ endpoint, options, auth, headers }) => {
  const resource = wretch()
    .headers({ Accept: 'application/json', ...headers })
    .url(endpoint)
    .middlewares(auth ? [authorization(auth)] : [])
    .options(options)
  return createApi(resource)
}

export const authorization = (authFn) => (next) => (url, opts) => {
  const authHeader = `Bearer ${authFn()}`
  opts.headers = { ...opts.headers, Authorization: authHeader }
  return next(url, opts)
}

/**
 * Creates a 'resource' with the given endpoint.
 * @param {*} endpoint The API's endpoint.
 * @param searchString The API's search string
 * @param queryStrings The API's query strings.
 * @param headers The API's headers.
 * @param auth The API's auth token.
 * @param options The API's options.
 * @returns Crud functions to be used in a service.
 */
export const createMapboxResource = (
  {
    endpoint,
    searchString = '',
    queryStrings = [],
    headers = {},
    auth = '',
    options = {}
  }) => {
  const resource = wretch()
    .url(endpoint + checkSearchString(searchString) + checkQueryStrings(queryStrings))
    .headers({ Accept: 'application/json', ...headers })
    .middlewares(auth ? [authorization(auth)] : [])
    .options(options)
  return createApi(resource)
}

const checkSearchString = (search) => {
  return search && `/${search}?`;
}

const checkQueryStrings = (query) => {
  return query.length > 0 ? `${query.join('&')}` : '';
}