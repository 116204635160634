import React, { useCallback } from 'react'
import { fromState } from '../utils'

export const useShowHeader = fromState(true)
export const useHeaderNavigation = fromState({ url: '', label: '' })
export const useBreadcrumbs = fromState([], (hookFn) => {
  const [breadcrumbs, setBreadcrumbs] = hookFn()

  const addBreadCrumb = useCallback(
    (breadcrumb) => {
      setBreadcrumbs((currentBreadcrumbs) => [...currentBreadcrumbs, breadcrumb])
    },
    [setBreadcrumbs, breadcrumbs]
  )

  const clearBreadcrumbs = useCallback(() => setBreadcrumbs([]), [setBreadcrumbs])

  return {
    addBreadCrumb,
    clearBreadcrumbs,
    breadcrumbs
  }
})
