import React from 'react'
import { Text } from '../../core'
import styles from './form.module.css'

const Button = ({
  type = 'button',
  classNames,
  variant,
  icon,
  children,
  disabled,
  ref,
  ...rest
}) => {
  return (
    <button
      ref={ref}
      type={type}
      {...rest}
      className={`
      ${classNames ? classNames : ''}
      ${styles.button} ${variant ? styles[variant] : ''} ${disabled ? styles.disabled : ''}
      `}
      {...rest}
    >
      {children}
      {icon && <div className={styles.icon}>{icon}</div>}
    </button>
  )
}

Button.Primary = ({ children, ...props }) => (
  <Button classNames={props.classes} variant='primary' {...props}>
    {children}
  </Button>
)
Button.Secondary = ({ children, ...props }) => (
  <Button classNames={props.classes} variant='secondary' {...props}>
    {children}
  </Button>
)
Button.Tertiary = ({ children, ...props }) => (
  <Button classNames={props.classes} variant='tertiary' {...props}>
    {children}
  </Button>
)
Button.Warning = ({ children, ...props }) => (
  <Button classNames={props.classes} variant='warning' {...props}>
    {children}
  </Button>
)
Button.Link = ({ children, ...props }) => (
  <Button variant='link' {...props}>
    {children}
  </Button>
)
Button.Danger = ({ children, ...props }) => (
  <Button classNames={props.classes} variant='danger' {...props}>
    {children}
  </Button>
)

Button.Icon = ({ icon, ...props }) => (
  <Button icon={icon} classNames={props.classes} variant='icon' {...props} />
)

Button.Kickstart = ({ children, kickstartIcon, title, finished, ...props }) => (
  <Button
    classNames={`${(props.classes && props.classes) || ''} ${finished ? styles.finished : ''}`}
    variant='kickstart'
    {...props}
  >
    {kickstartIcon && <div className={styles.kickstartIcon}>{kickstartIcon}</div>}
    <Text.Medium classes={`${styles.title}`}>{title}</Text.Medium>
    <div className={styles.kickstartLabel}>
      <Text.Medium classes={`${styles.vcenter} ${styles.kickstartLabel}`} color={'blue'}>
        {children}
      </Text.Medium>
    </div>
  </Button>
)

export { Button }
