import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const canvasResource = createResource({
  endpoint: `${API_URL}/hub/campaign-actions`,
  auth: () => userStorage[USER_TOKEN].get(),
  options: { mode: 'cors' }
})

const questionnaireResource = createResource({
  endpoint: `${API_URL}/hub/questionaires`,
  auth: () => userStorage[USER_TOKEN].get(),
  options: { mode: 'cors' }
})

/**
 *
 * Service for the account api.
 */
export const canvasService = {
  listActions: (params) => canvasResource.get(null, params).json(),
  getAction: (actionId) => canvasResource.url(actionId).get().json(),
  deleteAction: (actionId) => canvasResource.url(actionId).delete().res(),
  saveAction: (actionData) => canvasResource.post(actionData).json(),
  updateAction: (actionId, actionData) => canvasResource.url(actionId).post(actionData).json(),
  exportActionData: (actionId) => canvasResource.url(actionId).url('/download').get().blob(),
  getQuestionnaires: () => questionnaireResource.get().json()
}
