import { chiliService } from '../../services'
import { chiliConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })

const request = action(chiliConstants.PROFILE_REQUEST)
const success = action(chiliConstants.PROFILE_SUCCESS)
const failure = action(chiliConstants.PROFILE_FAILURE)

const getChiliUserProfile = () => (dispatch, getState) => {
  dispatch(request())
  chiliService
    .getUserProfile()
    .then((response) => dispatch(success(response)))
    .catch((err) => dispatch(failure(err)))
}

const getChiliAssets = () => (dispatch, getState) => {
  dispatch(request())
  const chiliApiKey = getState().chiliApiKey
  chiliService
    .getUserProfile()
    .then((response) => dispatch(success(response)))
    .catch((err) => dispatch(failure(err)))
}

export { getChiliUserProfile, getChiliAssets }
