export const errorReducer = (state, action) => {
  if (action.type.indexOf('FAILURE') > -1) {
    const { message, errors } = action.payload
    return { ...state, ...{ errorMessage: message, errors } }
  } else {
    if (action.type.indexOf('CLEAR') > -1) {
      return { ...state, errors: null, errorMessage: null }
    }
  }
  return state
}
