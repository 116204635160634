import React, { useCallback, useEffect, useMemo } from 'react'
import { isPastDate, sortActionsByDate } from '../../utils'
import { ActionDateTime } from '../actions/components/action.datetime'
import { ActionCard, LoadingIndicator } from '../card'
import { withCanvas } from '../hoc'
import Bitterball from '../../assets/images/bitterbal.png'
import { Button, Card, Text } from '../../core'
import { Column, Row } from 'simple-flexbox'
import { FiPlus } from 'react-icons/fi'
import { useNavigation } from '../../hooks'
import styles from './dashboard.module.css'

const PlannedActionsComponent = ({ loading, listActions, list: actionsList, onAddItem }) => {
  const { navigate, navigateWithQueryString } = useNavigation()

  useEffect(() => {
    listActions()
  }, [listActions])

  const sortedActions = useMemo(() => {
    return (actionsList && sortActionsByDate(actionsList)) || null
  }, [actionsList])

  const upcomingAction = useMemo(() => {
    if (sortedActions && sortedActions.length) {
      return sortedActions.find(({ datetime }) => !isPastDate(datetime))
    }
    return null
  }, [sortedActions])

  const gotoCanvasActions = useCallback(() => navigate('/actions'), [])

  const gotoAddCanvasAction = useCallback(() => {
    navigateWithQueryString({ path: '/actions', params: { mode: 'add' } })
  }, [])

  const gotoCanvasActionDetails = useCallback((actionId) => {
    navigateWithQueryString({ path: '/actions', params: { mode: 'details', action: actionId } })
  }, [])

  return (
    <LoadingIndicator loading={loading} title={'Loading action'}>
      <Card
        style={{ justifyContent: 'normal', flexGrow: 1 }}
        classes={`flexcenter tpad bpad lpad rpad nopadding gapL hgrow-0`}
      >
        <Row>
          <Column flexGrow={2}>
            <Text.SemiLarge classes={'semibold'} color={'black'}>
              {(upcomingAction && `Geplande canvasacties`) || `Canvasacties`}
            </Text.SemiLarge>
          </Column>
        </Row>

        <Column flexGrow={2}>
          {(upcomingAction && (
            <ActionCard
              title={<ActionDateTime action={upcomingAction} />}
              key={upcomingAction?.id}
              onClick={() => gotoCanvasActionDetails(upcomingAction?.id)}
              header={
                <span className={`${isPastDate(upcomingAction?.datetime) ? 'black' : ''}`}>
                  {upcomingAction?.name}
                </span>
              }
              subheader={
                <Text.Medium classes={'weightMedium'} color={'darkgray'}>
                  {upcomingAction?.address}
                </Text.Medium>
              }
              body={
                <>
                  <div className={'flex gapXs nomargin nopadding hcenter nowrap'}>
                    <Text.Small classes={'bold'}>
                      {upcomingAction?.volunteers_count || 0}
                    </Text.Small>
                    <Text.Small classes={'light'}>Vrijwilligers</Text.Small>
                  </div>
                  <div className={'flex gapXs nomargin nopadding hcenter nowrap'}>
                    <Text.Small
                      classes={'bold'}
                    >{`${upcomingAction?.results?.meatballs}`}</Text.Small>
                    <img className={styles.meatball} src={Bitterball} alt='Bitterbal' />
                  </div>
                </>
              }
            />
          )) || (
            <Text.Medium classes={'weightMedium'} color={'darkgray'}>
              Er is nog geen data beschikbaar
            </Text.Medium>
          )}
        </Column>
        {upcomingAction && (
          <Row className={'gapS'} justifyContent='space-between'>
            <Column flexGrow={1}>
              <Button.Secondary onClick={gotoCanvasActions}>Naar de kalender</Button.Secondary>
            </Column>
            <Column flexGrow={1}>
              <Button.Primary
                onClick={gotoAddCanvasAction}
                classes={'spaceEven hgrow-0'}
                icon={<FiPlus style={{ width: '16px', height: '16px' }} />}
              >
                Toevoegen
              </Button.Primary>
            </Column>
          </Row>
        )}
      </Card>
    </LoadingIndicator>
  )
}

export const PlannedActions = withCanvas(PlannedActionsComponent)
