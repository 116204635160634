import React from 'react'
import { errorReducer, getChiliUserProfile, chiliReducer } from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const chili = fromReducer(combineReducers({ chili: chiliReducer }, { error: errorReducer }))

export const withChili = withReducerState(
  (state) => {
    return { ...state?.data }
  },
  (dispatch) => {
    return {
      getChiliProfile: () => dispatch(getChiliUserProfile())
    }
  }
)(chili)
