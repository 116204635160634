.card {
  padding-bottom: 0 !important;
}

.cardcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.news-card {
  max-width: 242px;
  max-height: 297px;
  min-width: 242px !important;
  padding: 0px !important;
  margin: 0 16px 16px 0;
  border-radius: 8px;
  border: 1px solid var(--colors-lightgray);
  overflow: hidden;
}
.assetCard {
  padding: 1rem !important;
  min-width: auto !important;
  height: 12rem;
  background-color: var(--colors-lightgray);
}

.assetLink {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 18px;
  border-radius: 5px;
  border: 1px solid var(--colors-lightgray);
  margin-bottom: 12px;
}

.assetLink.disabled {
  pointer-events: none;
  opacity: 0.8;
  color: var(--colors-midgray);
}

.assetCard .body {
  justify-content: center;
}

.image {
  max-width: 242px;
  min-height: 181px;
  max-height: 181px;
  border-radius: 8px 8px 0 0;
}

.body {
  display: flex;
  flex-direction: column;
  max-width: 210px;
  padding: 0 16px;
  margin: 16px 0 16px 0 !important;
  height: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: end;
}

.text {
  font-size: 16px;
  margin-bottom: 6px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  max-width: 100%;
}

.loader {
  margin-left: 7px;
}

.downloaded {
  color: var(--colors-orange);
}
