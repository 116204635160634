.legendItem {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 1px;
  --fill-color: var(--colors-gray);
  background-color: var(--fill-color);
}

.legendLabel {
  line-height: 0.65rem;
}
