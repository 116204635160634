.wrapper {
  display: flex;
  flex-direction: row;
  margin: 0;
  height: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.header {
  top: 0;
  padding: 0;
  position: sticky;
  opacity: 0.94;
  background-color: var(--colors-background);
  z-index: 10;
}
.nav {
  z-index: 2;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  background-color: #fff;
  border-right: 1px solid rgb(0 0 0 / 6%);
}

.contentAuth {
  padding: 0 40px;
  margin-bottom: 20px;
  transition: all 2s ease-in;
}
.content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
/* 
@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
  .nav {
    position: sticky;
    top: 0;
    flex-grow: 0;
    height: 3.5rem;
    align-items: center;
    overflow: auto;
    justify-content: flex-start;
    flex-direction: row;
  }
  .nav * {
    display: flex;
    flex-direction: row;
  }
} */
