import React from 'react'
import { Button } from '../../../core'
import { VVDModal } from '../vvd.modal'

export const ActionDeleteModal = ({ cancel, confirm, title, onClose, children, ...rest }) => {
  return (
    <VVDModal
      closeOnClickOutside={true}
      title={title || 'Canvasactie verwijderen'}
      footer={
        <>
          <Button.Secondary onClick={cancel}>Annuleren</Button.Secondary>
          <Button.Danger onClick={confirm}>Verwijder canvasactie</Button.Danger>
        </>
      }
      content={
        'Weet je zeker dat je deze actie wilt verwijderen? Dit is niet meer terug te draaien'
      }
      {...(rest && {
        ...rest,
        onClose
      })}
    >
      {children}
    </VVDModal>
  )
}
