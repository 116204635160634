import React, { useCallback } from 'react'
import { isValidToken, tokenIsExpired } from '../utils'

export const useToken = () => {
  const isValid = useCallback((token) => {
    return isValidToken(token)
  }, [])

  const isExpired = useCallback((token) => {
    return tokenIsExpired(token)
  }, [])

  return {
    isValid,
    isExpired
  }
}
