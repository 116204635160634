import { useCallback } from 'react'
import { defaultBackground } from '../constants'

export const useTheme = () => {
  const setTheme = useCallback((theme) => {
    document.body.dataset.theme = theme || defaultBackground
  }, [])

  return setTheme
}
