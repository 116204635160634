import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const contactResource = createResource({
	endpoint: `${API_URL}/hub/contacts`,
	auth: () => userStorage[USER_TOKEN].get()
})
/**
 *
 * Service for the user api.
 */
export const contactService = {
	getContacts: () => {
		return contactResource.get().json()
	}
}
