import React, { memo } from 'react'
import { Card, Button } from '../../../core'
import { Column, Row } from 'simple-flexbox'
import { MapLegend, Map } from '../../map'

export const MapCard = memo(
  ({ className, styles, title, locationData = null, onRefresh = null }) => {
    return (
      <Card
        classes={`${
          (className && className) || ''
        } flexcenter tpad bpad lpad rpad nopadding gapL hgrow-0`}
        {...(styles && {
          style: styles
        })}
      >
        <Row>
          <Column flexGrow={2}>{title}</Column>
          {onRefresh && (
            <Column horizontal={'end'}>
              <Button.Link onClick={onRefresh}>Data verversen</Button.Link>
            </Column>
          )}
        </Row>
        <Row>
          <Column flexGrow={1} className={'gapL'} key={locationData?.time}>
            {locationData?.data && (
              <>
                <Map
                  locationData={locationData?.data}
                  locationTime={locationData?.time}
                  reverse={locationData?.reverse}
                ></Map>
                <MapLegend />
              </>
            )}
          </Column>
        </Row>
      </Card>
    )
  }
)
