import {createMapboxResource} from '.'
import { MAPBOX_URL, MAPBOX_ENDPOINT, MAPBOX_ACCESS_TOKEN } from '../constants'

/**
 *
 * Service for the Mapbox api.
 */
export const geocodeService = {
  find: (searchText) =>
    createMapboxResource({
      endpoint: MAPBOX_URL + MAPBOX_ENDPOINT,
      searchString: `${searchText}.json`,
      queryStrings: [
        'limit=5',
        'types=address',
        'language=NL',
        'country=NL',
        `access_token=${MAPBOX_ACCESS_TOKEN}`,
      ],
      options: { mode: 'cors' }
    })
      .get()
      .json()
}