import React from 'react'
import { Row, Column } from 'simple-flexbox'

import { Text } from '../../core'
import { MAP_LEGEND } from './map.constants'

import styles from './map.legend.module.css'

export const MapLegend = () => {
  return (
    <Row className={'gap'} alignItems='center' justifyContent='flex-start'>
      {MAP_LEGEND.map(({ color, label }) => (
        <Column flexGrow='0' key={label}>
          <Row alignItems='end' className={'gapXs'}>
            <span className={`${styles.legendItem}`} style={{ '--fill-color': color }} />
            <Text.Medium classes={styles.legendLabel} color={'darkgray'}>
              {label}
            </Text.Medium>
          </Row>
        </Column>
      ))}
    </Row>
  )
}
