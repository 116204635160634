.kickstarter {
  position: relative;
  background-color: var(--colors-blue);
  color: #fff;
}

.kickstarter .closeable {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.kickstarter .closeable:before {
  content: '';
  border-radius: 6px;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: var(--colors-darkblue);
}
.kickstarter .closeable:after {
  content: '';
  border-radius: 6px;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.9;
  background-repeat: repeat;
  background-size: 24%;
  background-image: url('../../../assets/images/pattern_bg_blue.png');
}
.kickstarter .closeable > div {
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kickstarter .closeable .button {
  font-size: 1rem;
}
.kickstarter .closeable .header {
  width: fit-content;
  margin-bottom: 2rem;
  top: 20%;
  text-shadow: -1px 1px var(--colors-black);
}
