import React from 'react'
import styles from './form.module.css'

const FormElement = ({ children, ...rest }) => {
  return (
    <div className={styles.formElement} {...rest}>
      {children}
    </div>
  )
}
export { FormElement }
