import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const newsResource = createResource({
  endpoint: `${API_URL}/hub/news-articles`,
  auth: () => userStorage[USER_TOKEN].get(),
  options: { mode: 'cors' }
})

/**
 *
 * Service for the news api.
 */
export const newsService = {
  listNewsItems: (params) => newsResource.get(null, params).json(),
  addNewsItems: (page) => newsResource.get(null, { page }).json(),
  getNewsItem: (newsItemId) => newsResource.url(newsItemId).get().json()
}
