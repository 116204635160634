import { downloadsConstants } from '../constants'

export const initialDownloadsState = {
  list: [],
  selectedDownloadsItem: null,
  loading: false,
  busy: false
}
const reducerSwitch = {
  [downloadsConstants.DOWNLOADS_START]: (state) => ({
    ...state,
    loading: true
  }),
  [downloadsConstants.DOWNLOADS_BUSY]: (state) => ({
    ...state,
    loading: false,
    busy: true
  }),
  [downloadsConstants.DOWNLOADS_END]: (state) => ({
    ...state,
    busy: false,
    loading: false
  }),

  [downloadsConstants.DOWNLOADS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedDownloadsItem: null,
    list: payload
  }),
  [downloadsConstants.DOWNLOADS_ITEM_GET_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedDownloadsItem: { ...payload }
  })
}

export const downloadsReducer = (state = initialDownloadsState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return initialDownloadsState
  }
}
