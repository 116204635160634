.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  gap: 1px;
}

.meatballGroup {
  display: flex;
  gap: 2px;
  flex-grow: 1;
}

.meatball {
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--colors-lightgray);
}
.meatball.fill {
  background-image: url('../../../assets/images/bitterbal.png');
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}
.meatball.empty {
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
}
.meatball.groupEnd {
  display: flex;
}

.meatballItem {
  display: flex;
  margin: 12px;
}
