import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { Button, LinkButton } from '../../core'
import { useShowHeader } from '../../hooks'
import { withAuth } from '../hoc/'
import ChevronIcon from '../../assets/images/chevron.svg'
import { Row } from 'simple-flexbox'
import styles from './header.module.css'

const useComponentVisible = (initialIsVisible, setOpen) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef()

  const handleHideDropdown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        return
      }
      //setOpen(false)
    },
    [setOpen]
  )

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        return
      }
      //setOpen(false)
    },
    [setOpen]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside, handleHideDropdown])

  return { ref, isComponentVisible, setIsComponentVisible }
}

const NavItem = ({ children, user }) => {
  const ref = useRef()

  const [open, setOpen] = useState(false)

  const handleClick = useCallback(
    (event) => {
      if (ref.current.contains(event.target)) {
        // inside click
        return
      }
      // outside click
      setOpen(false)
    },
    [setOpen]
  )

  const handleEscapeKey = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        return
      }
      setOpen(false)
    },
    [setOpen]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [handleClick, handleEscapeKey])

  return (
    <div
      className={`${styles.avatar_wrapper} ${open ? styles.open : ''}`}
      onClick={() => setOpen(!open)}
      ref={ref}
    >
      <Button variant='secondary' className={styles.button}>
        {user}
      </Button>
      <FiChevronDown className={`${styles.chevron} ${open ? styles.open : ''}`} size='2em' />
      {open && children}
    </div>
  )
}

NavItem.displayName = 'NavItem'

const DropdownMenu = ({ children, myRef }) => {
  return (
    <nav ref={myRef} className={styles.dropdownMenu}>
      <ul className={styles.dropdownMenuUnorderedList}>{children}</ul>
    </nav>
  )
}

const DropdownItem = ({ children }) => {
  return <li className={styles.dropdownItem}>{children}</li>
}

export const Header = withAuth(
  ({ user, logout, breadcrumb, breadcrumbs = [], showAvatar = true }) => {
    const headerVisible = useShowHeader()[0]
    const { ref } = useComponentVisible(true)

    const renderBreadCrumb = useMemo(() => {
      if (!breadcrumb) {
        return null
      }
      return (
        <div className={`${styles.nav}`}>
          <ChevronIcon />
          <LinkButton url={breadcrumb.url}>
            <div className={`${styles.navItem} weightMedium`}>{breadcrumb.label}</div>
          </LinkButton>
        </div>
      )
    }, [breadcrumb])

    const renderBreadCrumbs = useMemo(() => {
      if (!breadcrumbs.length || breadcrumbs.length === 1) {
        return null
      }
      const breadcrumbsToRender = breadcrumbs.filter((br) => br !== undefined)
      return (
        <Row alignItems='center' style={{ gap: '.5rem' }} className={`${styles.breadcrumbs}`}>
          {breadcrumbsToRender.map((br, idx) => (
            <div key={`br-${idx}`} className={styles.breadcrumb}>
              {(idx + 1 === breadcrumbsToRender.length && (
                <div className={`${styles.breadcrumb} ${styles.inactive}`}>{br.crumbName}</div>
              )) || (
                  <>
                    <LinkButton url={{ pathname: br.url, state: { ...br.state } }}>
                      <div className={`${styles.breadcrumb}`}>{br.crumbName}</div>
                    </LinkButton>
                    <FiChevronRight />
                  </>
                )}
            </div>
          ))}
        </Row>
      )
    }, [breadcrumbs])

    return headerVisible ? (
      <div className={styles.header}>
        {renderBreadCrumb}
        {renderBreadCrumbs}
        <div className={`${styles.avatar} ${!showAvatar ? styles.hide : ''}`} ref={ref}>
          <div className={styles.currentCampaign}>Europese Parlementsverkiezingen 2024</div>
          <NavItem {...{ user }}>
            <DropdownMenu>
              <DropdownItem>
                <Button.Link onClick={() => logout()}>Uitloggen</Button.Link>
              </DropdownItem>
            </DropdownMenu>
          </NavItem>
        </div>
      </div>
    ) : (
      <div style={{ marginTop: '50px' }}></div>
    )
  }
)
