import { accountConstants } from '../constants'

export const initialAccountState = {
  accountCreated: false,
  forgotPasswordHandled: false,
  loading: false
}
const reducerSwitch = {
  [accountConstants.REGISTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    accountCreated: true
  }),
  [accountConstants.REGISTER_REQUEST]: (state) => ({ ...state, loading: true }),
  [accountConstants.FAILURE]: (state) => ({
    ...state,
    loading: false
  }),
  [accountConstants.RESET_REQUEST]: (state) => ({ ...state, loading: true }),
  [accountConstants.RESET_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    forgotPasswordHandled: true
  })
}

export const accountReducer = (state = initialAccountState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return state
  }
}
