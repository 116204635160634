import React from 'react'
import { Card, Text, CardBody, LinkButton } from '../../../core'
import { downloadFetch } from '../../toolbox/hooks'
import styles from './content.module.css'
import DownloadIcon from '../../../assets/images/arrow_blue_down.svg'
import { downloadFetch } from '../../toolbox/hooks'
import { useDownload } from '../../../hooks'

const { getDownloadsItem: useGetDownloadsItem } = downloadFetch


export const ContentCard = ({ content, classes }) => {

  const setBlob = useDownload(content?.name)
	const [ getDownloadItem ] = useGetDownloadsItem({
		onSuccess: (res) => setBlob(res)
	})

  return (
    <Card classes={`${classes ? classes : ''}`}>
      <Text.Large classes={`semibold margin-bottom-24`} color={'black'}>
        Landelijke content
      </Text.Large>
      <div className={styles.cardcontainer}>
        {content &&
          content.map((singleContent) => {
            return (
              <Card key={singleContent.id} classes={styles[`news-card`]}>
                <div
                  style={{
                    backgroundImage: `url(${singleContent.featured_image?.url})`,
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '24rem',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                ></div>

                {/* <img className={styles.image} src={singleContent.image} alt='image' /> */}
                <CardBody classes={styles.body}>
                  <Text.Medium classes={`bold ${styles.text}`} color={'black'}>
                    {singleContent.name}
                  </Text.Medium>
                  <Text.Medium classNames={`textDefault ${styles.text}`} color={'darkgray'}>
                    {singleContent.info}
                  </Text.Medium>
                  <LinkButton.Download
                    classes={`textDefault  ${styles.link}`}
                    onClick={()=> getDownloadItem(singleContent.id)}
                  >
                    <DownloadIcon />
                    <span className={styles.loader}>Download</span>
                  </LinkButton.Download>
                </CardBody>
              </Card>
            )
          })}
      </div>
    </Card>
  )
}
