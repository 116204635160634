import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { Card, Text, LinkButton } from '../../../core'
import { useNavigation } from '../../../hooks'
import styles from './content.module.css'

export const CategoriesOverviewCard = ({ categories, classes }) => {
  const { url: currentUrl } = useRouteMatch()
  const { location } = useNavigation()
  return (
    <Card classes={`${classes ? classes : ''}`}>
      <Text.Large classes={`semibold margin-bottom-24`} color={'black'}>
        Maak je eigen campagne content
      </Text.Large>

      <Row wrap justifyContent='flex-start' style={{ columnGap: '1.5rem' }}>
        {categories &&
          categories.map(({ id, name }, idx) => (
            <Column key={`tpl-${idx}`} style={{ rowGap: '1rem' }}>
              <LinkButton.Medium
                url={{
                  pathname: `${currentUrl}/${id}`,
                  state: {
                    ...location.state,
                    category: name
                  }
                }}
                classes={`textDefault  ${styles.assetLink}`}
              >
                <span>{name}</span>
              </LinkButton.Medium>
            </Column>
          ))}
      </Row>
    </Card>
  )
}
