import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const mapResource = createResource({
  endpoint: `${API_URL}/hub/geo-json/coordinates`,
  auth: () => userStorage[USER_TOKEN].get()
})

/**
 *
 * Service for the map api.
 */
export const mapService = {
  getLocationData: () => {
    return mapResource.get().json()
  }
}
