import React from 'react'
import { Text } from '../../core'

export const Emoji = ({ hexCode, classes }) => {
  return <Text classNames={classes}>{String.fromCodePoint(hexCode)}</Text>
}
Emoji.Small = ({ ...props }) => <Emoji classes={'textSmall'} {...props} />
Emoji.Medium = ({ ...props }) => <Emoji classes={'textSemiLarge'} {...props} />
Emoji.Large = ({ ...props }) => <Emoji classes={'textLarge'} {...props} />
Emoji.XLarge = ({ ...props }) => <Emoji classes={'textXl'} {...props} />
