import React, { useEffect, useRef } from 'react'
import { LoadingIndicator } from '../card'
import { chiliFetch } from './hooks'

const { getTemplateRenderState: useGetTemplateRenderState } = chiliFetch

export const TemplateRenderState = ({
  templateId,
  renderId,
  setRenderState,
  interval = 5000,
  loadingMessage
}) => {
  const timerRef = useRef()

  const [getTemplateRenderState] = useGetTemplateRenderState({
    onSuccess: (res) => {
      const { status, url } = res?.data || {}
      if (status === 'rendered' && url !== null && url !== 'null') {
        setRenderState(res?.data?.url)
      }
    }
  })

  useEffect(() => {
    timerRef.current = setInterval(() => {
      getTemplateRenderState(templateId, renderId)
    }, interval)

    return () => clearInterval(timerRef.current)
  }, [templateId, interval, renderId])

  return <LoadingIndicator.Small loading={true} title={loadingMessage} />
}
