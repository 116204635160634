.hr {
  margin: 12px 0;
  border-bottom: 2px solid var(--colors-lightgray);
}

.flexTable {
  display: flex;
  flex-direction: column;
}

.flexTable thead {
  color: var(--colors-darkgray);
  margin: 6px 0;
}

.flexTable tbody {
  margin-left: -12px;
}

.flexTable thead tr,
.flexTable tbody tr {
  display: flex;
  gap: 12px;
}

.flexTable tbody tr {
  cursor: pointer;
}

.flexTable tbody tr:first-child {
  margin: 0;
}

.flexTable tbody tr {
  margin: 12px 0;
  padding: 20px 12px;
  cursor: pointer;
  /* gap: 12px; */
  gap: 1.5rem;
}

.flexTable tbody tr:hover {
  background-color: var(--colors-lightgray);
}

.flexTable.disabled * {
  pointer-events: none;
}

.flexTable tbody tr td {
  overflow: auto;
  text-overflow: ellipsis;
}

.flexTable .actionsHeader {
  display: flex;
}

.flexTable tbody tr .rowActions {
  display: flex;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
}

.rowActions div {
  position: relative;
}

.rowActions div:after {
  content: '';
  position: absolute;
  pointer-events: all;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.flexTable tbody tr:hover .rowActions {
  opacity: 1;
  pointer-events: all;
}

.rowActions div:hover *,
.rowActions div:active * {
  fill: var(--colors-orange);
}

.rowActions div:after:hover {
  fill: var(--colors-orange);
}

.flexTable thead tr th,
.flexTable tbody tr td {
  display: flex;
  flex: 1;
  font-weight: 400;
  font-size: var(--size-medium);
  align-items: center;
}

@media screen and (max-width: 768px) {
  .flexTable {
    border: 0;
  }

  .flexTable thead {
    display: none;
  }

  .flexTable tbody tr {
    flex-direction: column;
    margin: 12px;
    border-radius: 4px;
    border: 2px solid var(--colors-lightgray);
  }

  .flexTable tbody tr td {
    flex-direction: column;
    margin: 12px;
  }

  .flexTable tbody tr:first-child {
    margin: 12px;
  }

  .flexTable tbody tr td:before {
    display: flex;
    color: var(--colors-darkgray);
    align-items: center;
    margin: -0.5em -0.5em 0em -0.5em;
    padding: 0.5em;
    content: attr(data-label);
  }

  .flexTable tbody tr .rowActions {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-grow: 1;
  }

  .flexTable tbody tr .rowActions::before {
    display: none;
  }
}

.flexTable.disabled span.extraQuestionTooltip {
  pointer-events: all;
}

.extraQuestionTooltip:hover>div {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%) translateX(-50%);
}

.extraQuestionTooltip>div {
  visibility: hidden;
  width: 132px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  display: block;
  opacity: 0;
  transition: all .25s ease;
  transform: translateY(-25%) translateX(-50%);
}

.extraQuestionTooltip svg {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 3px;
}

.extraQuestionTooltip>div {
  width: 230px;
  z-index: 2;
  text-align: left;
  background-color: rgba(0, 0, 0, .8);
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
}