import { mapService } from '../../services'
import { mapConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })

const request = action(mapConstants.MAP_REQUEST)
const success = action(mapConstants.MAP_SUCCESS)
const failure = action(mapConstants.MAP_FAILURE)

const loadMapData = () => async (dispatch, getState) => {
  dispatch(request())

  mapService
    .getLocationData()
    .then((response) => {
      dispatch(success(response))
    })
    .catch((err) => {
      dispatch(failure(err))
    })
}

export { loadMapData }
