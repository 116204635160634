.ToggleHorizontal {
  border: 1px solid var(--colors-blue);
  border-radius: 30px;
  padding: 2px 1px;
  min-height: 42px;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.ToggleHorizontal button {
  margin: 0 2px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  padding: 10px 16px;
  border: none;
  background-color: white;
  color: var(--colors-blue);
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color .25s ease;
}

.ToggleHorizontal button:hover {
  background-color: var(--colors-lightblue);
}

.ToggleHorizontal button.selected {
  background-color: var(--colors-blue);
  color: var(--colors-white)
}
.ToggleHorizontal button .icon {
  padding-right: 8px;
}