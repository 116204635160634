import { accountService } from '../../services'
import { accountConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })

const registerRequest = action(accountConstants.REGISTER_REQUEST)
const registerSuccess = action(accountConstants.REGISTER_SUCCESS)
const forgotRequest = action(accountConstants.RESET_REQUEST)
const forgotSucces = action(accountConstants.RESET_SUCCESS)
const failure = action(accountConstants.FAILURE)

const registerAccount =
  ({ email, password, password_confirmation, token, pincode: code }) =>
  (dispatch, getState) => {
    dispatch(registerRequest())
    accountService
      .resetPassword({ email, password, password_confirmation, code, token })
      .then((response) => dispatch(registerSuccess(response)))
      .catch((err) => dispatch(failure(JSON.parse(err.message))))
  }

const forgotPassword =
  ({ email }) =>
  (dispatch, getState) => {
    dispatch(forgotRequest())
    accountService
      .forgotPassword({ email })
      .then((response) => {
        dispatch(forgotSucces(response))
      })
      .catch((err) => dispatch(failure(JSON.parse(err.message))))
  }

export { registerAccount, forgotPassword }
