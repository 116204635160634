.footer {
  display: flex;
  gap: 20px;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 2500;
  padding: 24px 0 24px 0;
  background-color: var(--colors-background);
}
.footer .left {
  justify-content: flex-start;
  display: flex;
  gap: 20px;
}

.footer .right {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  justify-content: flex-end;
}
