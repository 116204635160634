const saveItem = (key) => (value) => localStorage.setItem(key, value)
const getItem = (key) => () => localStorage.getItem(key)
const removeItem = (key) => () => localStorage.removeItem(key)

export const makeStorage = (...keys) => {
  return keys.reduce((memo, curr) => {
    memo[curr] = {
      save: saveItem(curr),
      get: getItem(curr),
      remove: removeItem(curr)
    }
    return memo
  }, {})
}
