.modal {
  border-radius: 4px;
  min-width: auto;
  max-width: none;
  width: 50vw;
  gap: 8px;
  max-height: 80vh;
  overflow: auto;
}
.modal .content {
  display: block;
}
.title {
  color: var(--colors-blue);
  font-size: var(--size-large);
  margin-top: -12px;
}
