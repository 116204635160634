import { campaignService } from '../../services'
import { campaignConstants } from '../constants'

const action = (actionType) => (payload) => ({ type: actionType, payload })

const request = action(campaignConstants.CAMPAIGN_REQUEST)
const success = action(campaignConstants.CAMPAIGN_SUCCESS)
const failure = action(campaignConstants.CAMPAIGN_FAILURE)

const getCampaignData = () => (dispatch, getState) => {
  dispatch(request())
  campaignService
    .getCampaignData()
    .then((response) => dispatch(success(response.data)))
    .catch((err) => dispatch(failure(err)))
}

export { getCampaignData }
