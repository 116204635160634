import React, { useState } from 'react'
import { VVDModal } from '../vvd.modal'
import { FiX } from 'react-icons/fi'
import styles from './voting.modal.module.css'

export const VotingGoalModal = ({
  cancel,
  confirm,
  title,
  onClose,
  children,
  content,
  ...rest
}) => {
  const [modalTitle, setTitle] = useState(title)
  const [loading, setLoading] = useState(false)

  return (
    <VVDModal
      closeOnClickOutside={false}
      closeIcon={<FiX size={'1.5rem'} color={'#0a2cca'} />}
      title={modalTitle}
      closeAfterLoadingReady={false}
      isLoading={!!loading}
      modalClass={styles.modal}
      titleClass={styles.title}
      content={content({ setLoading, onClose, setTitle })}
      {...(rest && {
        ...rest,
        onClose
      })}
    >
      {children}
    </VVDModal>
  )
}
