export const voteLabels = [
  'Zeer grote kans',
  'Kans zit erin',
  'Neutraal',
  'Kleine kans',
  'Nul kans'
]
export const emojis = {
  SUNGLASSES: 0x1f60e,
  NERD: 0x1f913,
  NEUTRAL: 0x1f610,
  DISAPPOINTED: 0x1f641,
  STUBBORN: 0x1f623,
  SLEEPING: 0x1f634,
  THUMBS_UP: 0x1f44d,
  THUMBS_DOWN: 0x1f44e,
  EYES: 0x1f440
}

export const voteIcons = [
  emojis.SUNGLASSES,
  emojis.NERD,
  emojis.NEUTRAL,
  emojis.DISAPPOINTED,
  emojis.STUBBORN
]

export const vibeIcons = [
  emojis.THUMBS_DOWN,
  emojis.NEUTRAL,
  emojis.NEUTRAL,
  emojis.NEUTRAL,
  emojis.THUMBS_UP
]
