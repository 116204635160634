export const canvasConstants = {
  ACTIONS_START: 'ACTION_START',
  ACTIONS_END: 'ACTION_END',

  ACTIONS_BUSY: 'ACTIONS_BUSY',

  ACTIONS_LIST_SUCCESS: 'ACTIONS_LIST_SUCCESS',
  ACTIONS_LIST_FAILURE: 'ACTIONS_LIST_FAILURE',

  ACTION_GET_SUCCESS: 'ACTION_GET_SUCCESS',
  ACTION_GET_FAILURE: 'ACTION_GET_FAILURE',

  ACTION_DELETE_START: 'ACTION_DELETE_START',
  ACTION_DELETE_SUCCESS: 'ACTION_DELETE_SUCCESS',
  ACTION_DELETE_FAILURE: 'ACTION_DELETE_FAILURE',

  ACTION_SAVE_SUCCESS: 'ACTION_SAVE_SUCCESS',
  ACTION_SAVE_FAILURE: 'ACTION_SAVE_FAILURE',

  ACTION_UPDATE_SUCCESS: 'ACTION_UPDATE_SUCCESS',
  ACTION_UPDATE_FAILURE: 'ACTION_UPDATE_FAILURE',

  FAILURE: 'FAILURE',

  ACTIONS_COMING_LIST_SUCCESS: 'ACTIONS_COMING_LIST_SUCCESS',
  ACTIONS_FINISHED_LIST_SUCCESS: 'ACTIONS_FINISHED_LIST_SUCCESS',
  ACTIONS_LIST_ADDED: 'ACTIONS_LIST_ADDED',
  ACTIONS_LIST_START: 'ACTIONS_LIST_START',
  ACTIONS_LIST_END: 'ACTIONS_LIST_END',

  CAMPAIGN_EXPORT_START: 'CAMPAIGN_EXPORT_START',
  CAMPAIGN_EXPORT_END: 'CAMPAIGN_EXPORT_END',
  CAMPAIGN_EXPORT_SUCCESS: 'CAMPAIGN_EXPORT_SUCCESS'
}
