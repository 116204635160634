.overviewTable {
  border-collapse: collapse;
}

.overviewTable tr td {
  height: 54px;
  font-size: 14px;
  padding: 0 16px;
}

.overviewTable tr td.bold {
  font-weight: bold;
}

.overviewTable thead td {
  font-size: 12px;
  color: #999;

  font-weight: 700;
}

.overviewTable tbody td {
  border-top: .9px solid #F2F3F4;
}

.scoreWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.scoreWrapper strong {
  width: 42px;
}

.meatballProgressor {
  max-width: 200px;
  display: inline-flex;
  margin-left: 24px;

}

.meatball {
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: var(--colors-lightgray);
  display: inline-block;
}

.meatball[data-filled="true"] {
  background-image: url('../../assets/images/bitterbal.png');
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}