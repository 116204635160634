import React, { useCallback, useState } from 'react'
import styles from './toolbox.module.css'
import { parseDate } from '../../utils'
import { Card, Text, LinkButton } from '../../core'
import DownloadIcon from '../../assets/images/arrow_blue_down.svg'
import { TemplateRenderState } from './template.renderstate'

export const TemplateRender = ({ templateId, render }) => {
  const [renderState, setRenderState] = useState({
    rendered: render?.status === 'rendered',
    url: render?.url
  })

  const setRenderUrl = useCallback(
    (url) => {
      setRenderState((state) => ({ ...state, rendered: true, url }))
    },
    [setRenderState]
  )

  return (
    <Card
      style={{ justifyContent: 'normal' }}
      classes={`flexcenter tpad bpad lpad rpad nopadding gapS hgrow-0`}
    >
      <Text.Small classes={`flexright semibold`} color={'darkgray'}>
        {parseDate(render.created_at, 1)('dd D MMM YYYY HH:mm')()}
      </Text.Small>
      {!renderState.rendered && (
        <TemplateRenderState
          renderId={render.id}
          interval={20000}
          loadingMessage={'PDF wordt gemaakt, even geduld aub...'}
          templateId={templateId}
          setRenderState={setRenderUrl}
        />
      )}
      {renderState.rendered && (
        <LinkButton.Download
          classes={`textDefault ${styles.assetLink}`}
          target={'_blank'}
          href={renderState.url}
          download
        >
          Download item {render.id} <DownloadIcon />
        </LinkButton.Download>
      )}
    </Card>
  )
}
