import { useState, useEffect } from 'react'
import { useDownloadBlob } from '.'

export const useDownload = (filename) => {
	const [ blob, setBlob ] = useState()
	const download = useDownloadBlob(blob)
	useEffect(
		() => {
			if (download) download(filename)
		},
		[ download, filename ]
	)
	return setBlob
}
