import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const ProtectedRoute = ({ access, redirectTo, children, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ match, location }) => {
        return access ? (
          (children && children(match, location)) || <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}
