import React, { useEffect, useRef, useState } from 'react'
import { TextField } from '..'
import styles from './pincode.module.css'
import formStyles from '../form.module.css'
import { FormInputLabel } from '../form-input-label'
import { FormElement } from '../form-element'

export const Pincode = ({ onChange, label, name, numberOfDigits = 5 }) => {
  const [formData, setFormState] = useState(Array.from(Array(numberOfDigits)))
  const [isValid, setIsValid] = useState(false)

  const pinRef = useRef([])

  const handleFormChange = (pinPartIdx) => (evt) => {
    setFormState((data) => {
      data[pinPartIdx] = evt.target.value || undefined
      return [...data]
    })
    const nextPinPart = pinPartIdx + 1
    if (pinPartIdx < numberOfDigits - 1) {
      pinRef.current[nextPinPart].focus()
    }
  }

  useEffect(() => {
    const pincodeCompleted = formData.filter((digit) => digit && digit.match(/^\d{1}$/))
    setIsValid(pincodeCompleted.length === numberOfDigits)
  }, [formData, setIsValid, numberOfDigits])

  useEffect(() => {
    onChange(name, isValid ? formData.join('') : null)
  }, [isValid, formData, onChange, name])

  return (
    <FormElement>
      <FormInputLabel htmlFor={name} label={label} />
      <p className={formStyles.subLabel}>Voer {numberOfDigits} cijfers in</p>
      <div className={styles.pincode}>
        {Array.from(Array(numberOfDigits).keys()).map((n, idx) => (
          <TextField
            ref={(node) => (pinRef.current[idx] = node)}
            key={idx}
            onFocus={(e) => e.target.select()}
            type='number'
            classes={styles.pinInput}
            autoComplete='off'
            onChange={handleFormChange(idx)}
            name={`pin${idx}`}
            placeholder='0'
          />
        ))}
      </div>
    </FormElement>
  )
}
