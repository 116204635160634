import React, { Suspense } from 'react'
import Rollbar from 'rollbar';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider, ErrorBoundary } from '@rollbar/react';
import { AppLayout, Navigation, AppRoutes, withAuth } from './components'
import { defaultRoute, mainRoutes, ROLLBAR_ENVIRONMENT, ROLLBAR_KEY } from './constants'
import './app.css'

const RollbarProvider = ({ children }) => {
  if (!(ROLLBAR_KEY && ROLLBAR_ENVIRONMENT)) return <>{children}</>

  const rollbarConfig = new Rollbar({
    accessToken: ROLLBAR_KEY,
    environment: ROLLBAR_ENVIRONMENT,
  })
  return (
    <Provider instance={rollbarConfig}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>)
}

export const App = withAuth(({ token, role, logout }) => {
  return (
    <RollbarProvider>
      <Router>
        <AppLayout
          navigation={
            <Navigation
              navigateHome='/'
              items={mainRoutes.map((r) => ({
                ...r,
                siblings: r.siblings || []
              }))}
            />
          }
        >
          <Suspense fallback={<div className='componentloader'></div>}>
            <AppRoutes token={token} role={role} logout={logout} defaultRoute={defaultRoute} />
          </Suspense>
        </AppLayout>
      </Router>
    </RollbarProvider>
  )
})
