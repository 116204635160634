import { downloadsService } from '../../services'
import { downloadsConstants } from '../constants'

const action = (actionType, payload) => ({ type: actionType, payload })
const failure = ({ message }) => action(downloadsConstants.FAILURE, JSON.parse(message))

const listAllDownloadsItems = () => (dispatch, getState) => {
	dispatch(action(downloadsConstants.DOWNLOADS_START))
	downloadsService
		.listDownloadsItems()
		.then((response) => dispatch(action(downloadsConstants.DOWNLOADS_LIST_SUCCESS, response.data)))
		.catch((err) => {
			dispatch(failure(err))
		})
		.finally(() => dispatch(action(downloadsConstants.DOWNLOADS_END)))
}

const getDownloadsItemById = (id) => (dispatch, getState) => {
	dispatch(action(downloadsConstants.DOWNLOADS_START))
	downloadsService
		.getDownloadsItem(id)
		.then((response) => dispatch(action(downloadsConstants.DOWNLOADS_ITEM_GET_SUCCESS, response)))
		.catch((err) => dispatch(failure(err)))
		.finally(() => dispatch(action(downloadsConstants.DOWNLOADS_END)))
}

export { listAllDownloadsItems, getDownloadsItemById }
