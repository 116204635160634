import React, { useState, useEffect, useMemo } from 'react'
import { ContentCard, HandyLinksCard, CategoriesOverviewCard, LoadingIndicator } from '../card'
import { Card, Text } from '../../core'
import { withDownloads } from '../hoc'
import { Column, Row } from 'simple-flexbox'
import styles from './toolbox.module.css'
import { chiliFetch } from './hooks'

const { getCategories: useGetCategories } = chiliFetch

export const ToolboxOverview = withDownloads(({ getAllDownloadsItems, list, loading }) => {
  const [categories, setCategories] = useState()

  const [getCategories, loadingCategories] = useGetCategories({
    onSuccess: (res) => setCategories(res?.data)
  })

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    getAllDownloadsItems()
  }, [])


  const links = useMemo(() => {
    if (!list || !list.length) {
      return null
    }
    return list.filter(item=> item.section!=='content').map(link=> {
      return <HandyLinksCard key={link.id} id={link.id} content={link}/>
    })
  }, [list])

  const content = useMemo(() => {
    if (!list || !list.length) {
      return null
    }
    return list.filter(item=> item.section!=='links')
  }, [list])


  return (
    <div>
      <div className={styles.header}>
        <h2>Toolbox</h2>
      </div>
      <Column>
        <Row className={`gapRelative`} alignItems={'start'} vertical='stretch'>
          <Column style={{ rowGap: '1.5rem' }} wrap flexBasis={'59%'}>
            <Column className={`gapRelative`} vertical='stretch'>
              <LoadingIndicator title={`Loading categories`} loading={loadingCategories}>
                <CategoriesOverviewCard categories={categories} />
              </LoadingIndicator>
            </Column>
            <Column>
            <LoadingIndicator title={`Loading content`} loading={loading}>
            <ContentCard content={content}/>
            </LoadingIndicator>
            </Column>
          </Column>
          <Column wrap flexGrow={1} flexBasis={'39%'}>
            <LoadingIndicator title={`Loading links`} loading={loading}>
              <Card>
                <Text.Large classes={`semibold margin-bottom-24`} color={'black'}>
                  Handige links
                </Text.Large>
                <div className={styles.cardcontainer}>
                  {links}
                </div>
              </Card>
            </LoadingIndicator>
          </Column>
        </Row>
      </Column>
    </div>
  )
})
