import { teamService } from '../../services'
import { teamConstants } from '../constants'

const action = (actionType, payload) => ({ type: actionType, payload })
const failure = ({ message }) => action(teamConstants.FAILURE, JSON.parse(message))

const listUsers = () => (dispatch, getState) => {
  dispatch(action(teamConstants.TEAM_START))
  teamService
    .listUsers()
    .then((response) => dispatch(action(teamConstants.TEAM_LIST_SUCCESS, response.data)))
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(teamConstants.TEAM_END)))
}
const getUserById =
  ({ id }) =>
  (dispatch, getState) => {
    dispatch(action(teamConstants.TEAM_START))
    teamService
      .getUser(id)
      .then((response) => dispatch(action(teamConstants.USER_GET_SUCCESS, response.data)))
      .catch((err) => dispatch(failure(err)))
      .finally(() => dispatch(action(teamConstants.TEAM_END)))
  }

const saveUser = (userData, callback) => (dispatch, getState) => {
  dispatch(action(teamConstants.TEAM_BUSY))
  teamService
    .saveUser({ ...userData })
    .then((response) => {
      dispatch(action(teamConstants.USER_SAVE_SUCCESS, response.data))
      callback()
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => {
      dispatch(action(teamConstants.TEAM_END))
    })
}

const updateUser = (id, userData, callback) => (dispatch, getState) => {
  dispatch(action(teamConstants.TEAM_BUSY))
  teamService
    .updateUser(id, { ...userData })
    .then((response) => {
      dispatch(action(teamConstants.USER_UPDATE_SUCCESS, response))
      callback()
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => {
      dispatch(action(teamConstants.TEAM_END))
    })
}

const deleteUser = (id) => (dispatch, getState) => {
  dispatch(action(teamConstants.TEAM_BUSY))
  teamService
    .deleteUser(id)
    .then(() => {
      dispatch(action(teamConstants.USER_DELETE_SUCCESS))
    })
    .catch((err) => {
      dispatch(failure(err))
    })
    .finally(() => dispatch(action(teamConstants.TEAM_END)))
}

const inviteUser = (id) => (dispatch, getState) => {
  dispatch(action(teamConstants.TEAM_BUSY))
  teamService
    .inviteUser(id)
    .then((response) => {
      dispatch(action(teamConstants.USER_INVITE_SUCCESS, response))
    })
    .catch((err) => dispatch(failure(err)))
    .finally(() => dispatch(action(teamConstants.TEAM_END)))
}

export { listUsers, getUserById, saveUser, updateUser, inviteUser, deleteUser }
