import { CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER } from './auth.constants'
/**
 * Entries used for navigation
 */
export const mainRoutes = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    private: true,
    roles: [CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER],
    show: true
  },
  {
    name: 'Team',
    url: '/team',
    private: true,
    roles: [CAMPAIGN_MANAGER],
    show: true
  },
  {
    name: 'Canvasacties',
    url: '/actions',
    private: true,
    roles: [CAMPAIGN_MANAGER],
    show: true
  },
  {
    name: 'Toolbox',
    url: '/toolbox',
    private: true,
    roles: [CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER],
    siblings: ['/content'],
    show: true
  },
  { name: 'Geen toegang', url: '/no-access', private: true, show: false },
  { name: 'Content', url: '/content', private: true, show: false, siblings: ['/toolbox'] },
  {
    name: 'Nieuws',
    url: '/news',
    private: true,
    roles: [CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER],
    show: true
  },
  {
    name: 'Contact',
    url: '/contact',
    private: true,
    roles: [CAMPAIGN_MANAGER, PROVINCIAL_CAMPAIGNMANAGER],
    show: true
  }
]
