import React from 'react'
import { useNavigation } from '../../hooks'
import styles from './navigation.module.css'

import VVDLogo from '../../assets/images/vvd-logo.svg'
import VVDArrow from '../../assets/images/vvd-pijl.svg'
import { withUser } from '../hoc'
import { LinkButton } from '../../core'

export const Navigation = withUser(({ items, role, subtitle, navigateHome }) => {
  const { navigate, currentLocation } = useNavigation()

  return (
    <div className={styles.navigation}>
      <div className={styles.logoTitleWrapper} onClick={(_) => navigate(navigateHome)}>
        <VVDLogo className='hello' />
        <div className={styles.title_placeholder}>Campagnehub</div>
        <div className={styles.title_placeholder}>{subtitle}</div>
      </div>

      {items &&
        items
          .filter((item) => !!item.show && (item.roles ? item.roles.includes(role) : true))
          .map((item, idx) => (
            <div key={`nav-${idx}`} className={styles.navigationItemWrapper}>
              {(item.url.startsWith(currentLocation) ||
                currentLocation === item.url ||
                currentLocation.startsWith(item.url) ||
                item?.siblings?.find((s) => currentLocation.startsWith(s))) && (
                  <VVDArrow className={styles.vvdArrow} />
                )}

              <LinkButton
                direct
                key={`nav-${idx}`}
                classes={`${styles.light} ${currentLocation === item.url ||
                  currentLocation.startsWith(item.url) ||
                  item?.siblings?.find((s) => currentLocation.startsWith(s))
                  ? styles.selected
                  : ''
                  }`}
                url={item.url}
              >
                {item.name}
              </LinkButton>
            </div>
          ))}
    </div>
  )
})
