import { useRef, useCallback, useState, useEffect } from 'react'

/**
 * React custom hook.
 * This hook manages click events on the document and the target component.
 * When clicked outside the target, the visible state changes.
 * @param {*} isVisible
 */
export const useEscapeKey = (initialValue) => {
  const [escapePressed, setEscapePressed] = useState(initialValue)

  const handleKeyPress = (event) => {
    const { key } = event
    if (key && key === 'Escape') {
      setEscapePressed(true)
    } else {
      setEscapePressed(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress, true)
    return () => {
      document.removeEventListener('keyup', handleKeyPress, true)
    }
  }, [])

  return escapePressed
}
