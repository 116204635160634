import React from 'react'
import { Text } from '../../core'
import styles from './loading.module.css'

export const LoadingIndicator = ({
  loading,
  pageHeader,
  title,
  renderText: RenderText = Text.SemiLarge,
  children
}) => {
  return (
    <>
      {(!loading && children) || null}
      {loading && !!pageHeader && (
        <h2 className={`${styles.header} loadingIndicator`}>{title}...</h2>
      )}
      {loading && !pageHeader && (
        <div classes={'hgrow-0 inlineflex'}>
          <RenderText classes={`hgrow-0 semibold loadingIndicator`} color={'black'}>
            {title}
          </RenderText>
        </div>
      )}
    </>
  )
}

LoadingIndicator.Small = ({ ...rest }) => {
  return <LoadingIndicator renderText={Text.Medium} {...rest} />
}
