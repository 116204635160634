import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useTheme } from '.'

export const useLazyPageModes = (pageModes, qsKey, defaultMode) => {
  const { fromQueryString } = useHistory()
  const pageMode = fromQueryString(qsKey) || defaultMode
  const [pageConfig, setPageConfig] = useState({
    resource: null,
    name: '',
    header: null,
    breadcrumb: false,
    breadcrumbs: [],
    bgColor: null,
    showAvatar: true
  })
  const changeBackground = useTheme()

  useEffect(() => {
    const loadModule = async () => {
      const { lazy, header, bgColor, breadcrumb, showAvatar, breadcrumbs } =
        pageModes[pageMode] || pageModes[defaultMode]
      setPageConfig({
        name: pageMode,
        resource: lazy,
        header,
        breadcrumb,
        breadcrumbs,
        bgColor,
        showAvatar
      })
    }
    if (pageMode) loadModule()
  }, [pageMode, setPageConfig, defaultMode, pageModes])

  useEffect(() => {
    if (pageConfig && pageConfig.bgColor) {
      changeBackground(pageConfig.bgColor)
    }
  }, [pageConfig, pageConfig.bgColor, changeBackground])

  const renderDynamicMod = useMemo(() => {
    if (!pageConfig.resource) return null
    return pageConfig.resource
  }, [pageConfig])

  return [pageConfig, renderDynamicMod]
}
