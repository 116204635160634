@keyframes loading {
  to {
    background-position: 315px 0, 0 0;
  }
}

.news-card {
  max-width: 289px;
  max-height: 342px;
  padding: 0px !important;
  margin: 0 24px 24px 0;
  flex-grow: 1;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}

.news-card.loading {
  background:
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(var(--colors-card-hover), var(--colors-card-hover));
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 100%;
  background-position: -315px 0, 0 0;
  animation: loading 1.5s infinite;
  box-shadow: inset -1px -1px 4px var(--colors-midgray);
}

.image {
  width: 100%;
  height: 120px;
}

.body {
  padding: 0 24px;
  margin: 24px 0 27px 0 !important;
  height: 174px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  width: 100%;
  margin-bottom: 8px;
}

.title {
  width: 100%;
  max-height: 93px;
  margin-bottom: 8px;
  line-height: 31.2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  text-overflow: ellipsis;
}