import { createResource } from '.'
import { API_URL, LOGIN_TYPE } from '../constants'

const authResource = createResource({
  endpoint: `${API_URL}/hub`,
  options: { mode: 'cors' }
})

/**
 *
 * Service for the auth api.
 */

export const authService = {
  loginUser: ({ email, password, type = LOGIN_TYPE }) =>
    authResource.url('login').post({ email, password, type }).json(),
  logoutUser: (...args) =>
    authResource
      .url('logout')
      .post(...args)
      .json()
}
