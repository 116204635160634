import React, { useCallback, useEffect, useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { mainRoutes } from '../../constants'
import { useNavigation } from '../../hooks'
import { tokenIsExpired } from '../../utils'
import { ProtectedRoute } from './protected-route'

const LoginPage = React.lazy(() => import('../../pages/login.page'))
const ForgotPasswordPage = React.lazy(() => import('../../pages/forgotpassword.page'))
const ResetPasswordPage = React.lazy(() => import('../../pages/resetpassword.page'))
const RegisterPage = React.lazy(() => import('../../pages/register.page'))
const DashboardPage = React.lazy(() => import('../../pages/dashboard.page'))
const TeamPage = React.lazy(() => import('../../pages/team.page'))
const ActionsPage = React.lazy(() => import('../../pages/actions.page'))
const ToolboxPage = React.lazy(() => import('../../pages/toolbox.page'))
const ToolboxCategoryPage = React.lazy(() => import('../../pages/toolbox/category.page'))
const ToolboxTemplatePage = React.lazy(() => import('../../pages/toolbox/template.page'))
const ToolboxTemplateEditorPage = React.lazy(() => import('../../pages/toolbox/editor.page'))
const ContactPage = React.lazy(() => import('../../pages/contact.page'))
const NewsPage = React.lazy(() => import('../../pages/news.page'))
const NotFoundPage = React.lazy(() => import('../../pages/notfound.page'))
const NoAccessPage = React.lazy(() => import('../../pages/noaccess.page'))

const toolboxRoutes = [
  {
    path: '/toolbox',
    name: (params) => 'Toolbox',
    Component: ToolboxPage
  },
  {
    path: '/toolbox/:categoryId',
    name: (params, state) => state?.category || 'Categorie',
    Component: ToolboxCategoryPage
  },
  {
    path: '/toolbox/:categoryId/:templateId',
    name: (params, state) => state?.template || 'Template',
    Component: ToolboxTemplatePage
  },
  {
    path: '/toolbox/:categoryId/:templateId/editor',
    name: (params, state) => state?.editor || 'Bewerken',
    Component: ToolboxTemplateEditorPage
  }
]

export const AppRoutes = ({ token, role, logout, defaultRoute = '/dashboard' }) => {
  const { replaceWithOriginalLocation, history } = useNavigation()
  const mounted = useRef()

  // Make sure 'public routes' won't get rendered when user is already authenticated:
  useEffect(() => {
    return history.listen((location) => {
      if (
        token &&
        !mainRoutes.find(
          (mr) => mr.url === location.pathname || location.pathname.startsWith(mr.url)
        )
      ) {
        return history.push(defaultRoute)
      }
    })
  }, [history, token])

  useEffect(() => {
    if ((token && !role) || (token && tokenIsExpired(token))) {
      logout()
      history.push('/login')
    }
  }, [token])

  // Make sure we start at the top of the 'page'
  useEffect(() => {
    return history.listen((history) => {
      if (!window.historyState) window.historyState = [history]
      else window.historyState.push(history)
      window.scrollTo(0, 0)
    })
  }, [history])

  useEffect(() => {
    if (token) {
      if (!mounted.current) {
        mounted.current = true
        replaceWithOriginalLocation(defaultRoute)
      }
    } else {
      if (mounted.current) {
        history.push('/login')
      }
    }
  }, [token, replaceWithOriginalLocation])

  const hasRouteAccess = useCallback(
    (path) => {
      const route = mainRoutes.find(({ url }) => url === path)
      return route && route.roles ? route.roles.includes(role) : true
    },
    [token, role]
  )

  return (
    <Switch>
      <Route exact path='/create-password' component={RegisterPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/forgotpassword' component={ForgotPasswordPage} />
      <Route exact path='/reset-password' component={ResetPasswordPage} />
      <ProtectedRoute
        exact
        path='/dashboard'
        access={hasRouteAccess('/dashboard')}
        redirectTo={role ? '/no-access' : '/login'}
        component={DashboardPage}
      />
      <ProtectedRoute
        exact
        path='/team'
        access={hasRouteAccess('/team')}
        redirectTo={role ? '/no-access' : '/login'}
        component={TeamPage}
      />
      <ProtectedRoute
        exact
        path='/actions'
        access={hasRouteAccess('/actions')}
        redirectTo={role ? '/no-access' : '/login'}
        component={ActionsPage}
      />
      <ProtectedRoute
        exact
        path='/news'
        access={hasRouteAccess('/news')}
        redirectTo={role ? '/no-access' : '/login'}
        component={NewsPage}
      />

      {toolboxRoutes.map(({ path, Component }, idx) => (
        <ProtectedRoute
          key={`toolbox-route-${idx}`}
          exact
          access={hasRouteAccess(path)}
          redirectTo={role ? '/no-access' : '/login'}
          path={path}
        >
          {(match, location) => {
            const crumbs = toolboxRoutes
              // Get all routes that contain the current one.
              .filter(({ path }) => match.path.includes(path))
              // Swap out any dynamic routes with their param values.
              .map(({ path, name, ...rest }) => ({
                url: Object.keys(match.params).length
                  ? Object.keys(match.params).reduce(
                      (path, param) => path.replace(`:${param}`, match.params[param]),
                      path
                    )
                  : path,
                crumbName: name(match.params, location.state),
                state: location.state,
                ...rest
              }))
            return <Component breadcrumbs={crumbs} {...match.params} />
          }}
        </ProtectedRoute>
      ))}

      <ProtectedRoute
        exact
        path='/contact'
        access={hasRouteAccess('/news')}
        redirectTo={role ? '/no-access' : '/login'}
        component={ContactPage}
      />
      <Route exact path='/no-access' redirectTo='/login' component={NoAccessPage} />
      <Route
        exact
        path='/'
        render={({ location }) => {
          if (role) {
            return <Redirect to={{ from: { pathname: defaultRoute } }} />
          } else if (location?.pathname !== '/login') {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            )
          } else {
            return null
          }
        }}
      />
      <Route component={NotFoundPage} />
    </Switch>
  )
}
