.header {
  margin-bottom: 10px;
  max-width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes loader-animation {
  0% {
    left: -100%;
  }
  49% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
.loader {
  height: 3px;
  width: 100%;
}
.loader .infinite {
  width: 100%;
  position: absolute;
  height: 3px;
  background-color: var(--colors-orange);
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
