import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const votingGoalResource = createResource({
  endpoint: `${API_URL}/hub/dashboard/voting-goal`,
  auth: () => userStorage[USER_TOKEN].get()
})
/**
 *
 * Service for the voting goal api.
 */
export const votingGoalService = {
  getVotingGoal: () => {
    return votingGoalResource.get().json()
  },
  saveVotingGoal: (votingGoalData) => votingGoalResource.post({ ...votingGoalData }).res()
}
