import { teamConstants } from '../constants'

export const initialTeamState = {
  list: [],
  selectedUser: null,
  loading: false,
  busy: false,
  loadingDelete: false,
  deletedUserId: null,
  inviteHandled: false
}
const reducerSwitch = {
  [teamConstants.TEAM_START]: (state) => ({
    ...state,
    deletedUserId: null,
    loading: true
  }),
  [teamConstants.TEAM_BUSY]: (state) => ({
    ...state,
    deletedActionId: null,
    loading: false,
    busy: true
  }),
  [teamConstants.TEAM_END]: (state) => ({
    ...state,
    loadingDelete: false,
    busy: false,
    loading: false
  }),

  [teamConstants.TEAM_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: payload,
    inviteHandled: false
  }),
  [teamConstants.USER_GET_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedUser: { ...payload }
  }),
  [teamConstants.USER_SAVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: [...state.list, payload]
  }),

  [teamConstants.USER_UPDATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedUser: { ...payload }
  }),

  [teamConstants.USER_INVITE_REQUEST]: (state) => ({ ...state, loading: true }),
  [teamConstants.USER_INVITE_SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedUser: { ...payload },
    loading: false,
    inviteHandled: true
  }),

  [teamConstants.USER_DELETE_START]: (state) => {
    return { ...state, loadingDelete: true }
  },

  [teamConstants.USER_DELETE_SUCCESS]: (state) => {
    return { ...state, deletedUserId: state.selectedUser?.id, selectedUser: null }
  }
}

export const teamReducer = (state = initialTeamState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return initialTeamState
  }
}
