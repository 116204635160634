import React from 'react'
import { LinkButton } from '../../../core'
import styles from './handy-links.module.css'
import DownloadIcon from '../../../assets/images/arrow_blue_down.svg'
import UrlIcon from '../../../assets/images/link.svg'
import { downloadFetch } from '../../toolbox/hooks'
import { useDownload } from '../../../hooks'

const { getDownloadsItem: useGetDownloadsItem } = downloadFetch

export const HandyLinksCard = ({ content, id }) => {
	const setBlob = useDownload(content?.name)
	const [ getDownloadItem ] = useGetDownloadsItem({
		onSuccess: (res) => setBlob(res)
	})
	
	return content.type === 'file' ? (
		<LinkButton.Download classes={`textDefault  ${styles.link}`} key={content.id} onClick={()=>getDownloadItem(id)}>
			{content.name}
			{<DownloadIcon />}
		</LinkButton.Download>
	) : (
		<LinkButton.External classes={`textDefault  ${styles.link}`} key={content.id} url={content.location}>
			{content.name}
			{<UrlIcon />}
		</LinkButton.External>
	)
}
