import React from 'react'
import { Card, CardBody, CardTitle, CardHeader } from '../../../core'
import styles from './actioncard.module.css'

export const ActionCard = ({
  title,
  header,
  subheader,
  headerIcon,
  isPastDate = false,
  body,
  ...rest
}) => {
  return (
    <Card
      {...rest}
      clickable
      classes={`${styles.details} ${styles.interactive}  ${isPastDate ? styles.expired : ''}`}
    >
      {title && (
        <>
          <CardTitle classes={styles.title}>{title}</CardTitle>
          <div className={styles.hr}></div>
        </>
      )}
      {header && (
        <>
          <CardHeader classes={styles.header}>
            {header}
            {subheader && subheader}
          </CardHeader>
        </>
      )}
      {body && <CardBody classes={styles.body}>{body}</CardBody>}
    </Card>
  )
}
