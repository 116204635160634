/**
 * Entries used for navigation
 */
export const navigationItems = [
  {
    name: 'Acties',
    url: '/actions',
    rank: 3,
    private: true
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    rank: 1,
    private: true
  },
  {
    name: 'Team',
    url: '/team',
    rank: 2,
    private: true
  }
]

export const defaultRoute = '/dashboard'
