import { votingGoalConstants } from '../constants'

export const initialState = {
  voting_goal: null,
  loading: false,
  saved: false
}
const reducerSwitch = {
  [votingGoalConstants.VOTINGGOAL_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    voting_goal: { ...payload }
  }),
  [votingGoalConstants.VOTINGGOAL_REQUEST]: (state) => ({ ...state, saved: false, loading: true }),
  [votingGoalConstants.VOTINGGOAL_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload
  }),
  [votingGoalConstants.VOTINGGOAL_SAVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    saved: true,
    loading: false
  }),
  [votingGoalConstants.VOTINGGOAL_SAVE_REQUEST]: (state) => ({
    ...state,
    saved: false,
    loading: true
  }),
  [votingGoalConstants.VOTINGGOAL_SAVE_FAILURE]: (state, { payload }) => ({
    ...state,
    saved: false,
    loading: false,
    error: payload
  })
}

export const votingGoalReducer = (state = initialState, action) => {
  try {
    return reducerSwitch[action.type](state, action)
  } catch {
    return state
  }
}
