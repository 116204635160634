import { createResource } from '.'
import { API_URL, USER_TOKEN } from '../constants'
import { userStorage } from '../utils'

const chiliResource = createResource({
  endpoint: `${API_URL}/hub/chili`,
  auth: () => userStorage[USER_TOKEN].get(),
  options: { mode: 'cors' }
})

/**
 *
 * Service for the chili publisher api.
 */
export const chiliService = {
  getCategories: () => chiliResource.url('categories').get().json(),
  getTemplatesByCategory: (categoryId) =>
    chiliResource.url(`categories/${categoryId}/templates`).get().json(),
  getTemplateDetails: (templateId) => chiliResource.url(`templates/${templateId}`).get().json(),
  getRenderedTemplates: (templateId) => {
    return chiliResource.url(`templates/${templateId}/renders`).get().json()
  },
  openTemplateEditor: (templateId) =>
    chiliResource.url(`templates/${templateId}/open`).get().json(),
  startRender: (templateId) => chiliResource.url(`templates/${templateId}/render`).get().json(),
  getTemplateRenderState: (templateId, renderId) =>
    chiliResource.url(`templates/${templateId}/renders/${renderId}`).get().json()
}
