import {
  downloadsReducer,
  errorReducer,
  listAllDownloadsItems,
  getDownloadsItemById
} from '../../state'
import { fromReducer, withReducerState, combineReducers } from '../../utils'

const downloads = fromReducer(
  combineReducers({ downloadsItems: downloadsReducer }, { error: errorReducer })
)

export const withDownloads = withReducerState(
  (state) => {
    return { ...state?.downloadsItems }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' }),
      getAllDownloadsItems: () => dispatch(listAllDownloadsItems()),
      getDownloadsItem: (id) => dispatch(getDownloadsItemById(id))
    }
  }
)(downloads)

export const withDownloadsErrors = withReducerState(
  (state) => {
    return { ...state?.error }
  },
  (dispatch) => {
    return {
      clear: () => dispatch({ type: 'CLEAR' })
    }
  }
)(downloads)
