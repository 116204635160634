import React from 'react'
import { Link } from 'react-router-dom'
import styles from './display.module.css'

const LinkButton = ({ classes, children, url, direct, ...props }) => {
	// If page is same as referrer, just navigate back to preserve historyState
	const interceptHistory = (e) => {
		if (!window.historyState || !window.historyState.length) return
		const previousPage = window.historyState[window.historyState.length - 1]
		if (!direct && previousPage && previousPage.pathname === url && (url !== location.pathname || previousPage.search)) {
			e.preventDefault()
			window.history.back()
		}
	}
	return (
		<Link {...{ onClick: interceptHistory, ...props }} className={`${classes ? classes : ''} ${styles.link} `} to={url}>
			{children}
		</Link>
	)
}

LinkButton.Large = ({ children, ...props }) => (
	<LinkButton classes={`${styles.large}`} {...props}>
		{children}
	</LinkButton>
)
LinkButton.Medium = ({ children, ...props }) => (
	<LinkButton classes={`${styles.medium}`} {...props}>
		{children}
	</LinkButton>
)
LinkButton.Small = ({ children, ...props }) => (
	<LinkButton classes={`${styles.small}`} {...props}>
		{children}
	</LinkButton>
)

LinkButton.External = ({ ...props }) => {
	const handleOnClick = (e) => {
		e.preventDefault()
		window.location = props.url
	}
	return (
		<a onClick={handleOnClick} href={props.url} className={`${styles.link} ${styles.medium} ${props.classes}`}>
			{props.children}
		</a>
	)
}

LinkButton.Download = ({ ...props }) => {
	return (
		<a {...props} className={`${styles.link} ${props.classes}`}>
			{props.children}
		</a>
	)
}
export { LinkButton }
