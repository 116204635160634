import React, { useCallback, useRef } from 'react'
import styles from './table.module.css'
import QuestionMarkIcon from '../../../assets/images/questionmark.svg'

export const Table = ({ cols, rows, rowActions, onRowClick, classes }) => {
  const rowsRef = useRef()

  const { onActionClick, items } = rowActions || {}
  const tbodyClickHandler = useCallback(
    (capture, idx, rowIdx) => (e) => {
      if (capture) {
        e.stopPropagation()
        e.preventDefault()
        return onActionClick(idx, rowIdx)
      }
      const { target } = e
      const targetNodeIdx = Array.from(rowsRef.current.childNodes).findIndex(
        (child) => child.contains(target) || child === target
      )
      if (targetNodeIdx > -1) {
        return onRowClick(targetNodeIdx)
      }
    },
    [rows, onActionClick, onRowClick]
  )

  const renderTooltip = (content) => {
    return (
      <span className={styles.extraQuestionTooltip}>
        <QuestionMarkIcon />
        <div>{content}</div>
      </span>
    )
  }

  return (
    <>
      <table
        className={`${classes ? classes : ''} ${styles.flexTable} ${
          !onRowClick ? styles.disabled : ''
        }`}
      >
        <thead>
          <tr>
            {cols.map((colHeader, idx) => (
              <th key={`colh-${idx}`}>
                {colHeader.name}
                {colHeader.tooltip && renderTooltip(colHeader.tooltip)}
              </th>
            ))}
            {rowActions && <th className={styles.actionsHeader}></th>}
          </tr>
          <tr className={styles.hr}></tr>
        </thead>
        <tbody ref={rowsRef} onClick={tbodyClickHandler(false)}>
          {rows.map((row, mainRowIdx) => {
            return (
              <tr key={`tr-${mainRowIdx}`}>
                {row.map((rowData, rowIdx) => {
                  return (
                    <td key={`td-${rowIdx}`} data-label={rowData.col}>
                      {rowData.data}
                    </td>
                  )
                })}
                {items && (
                  <td className={styles.rowActions}>
                    {items.map((rowAction, iconIdx) => (
                      <div
                        key={`action-${iconIdx}`}
                        onClickCapture={tbodyClickHandler(true, iconIdx, mainRowIdx)}
                      >
                        <rowAction.icon />
                      </div>
                    ))}
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
